import React from 'react';
// import Slider from "react-slick";
import { connect } from 'react-redux';

import AppContext from 'app/AppContext';
import RafalaApi from 'api/Rafala';
import Product from './Product';

class FeaturedProducts extends React.Component {
    state = {
        items: null
    };

    async componentDidMount() {
        const response = await RafalaApi.Items2(AppContext.s['project-id'], null, { }, 1, AppContext.IsRetail);
        console.log(response);

        if(response && response.data) {
            const items = response.data.data;

            const productCards = !items ? [] : items.map(product => {
                return (<Product key={product.id} product={product} onClickProduct={this.props.onClickProduct}
                    addToCart={this.addToCart} enableCart={true}
                    inCart={this.props.cart.length > 0 && this.props.cart.filter(e => e.product.id === product.id).length > 0 } />);
            });

            this.setState({
                items: productCards
            });
        }
    }

    render() {
        const {items} = this.state;

        if(!items || items.length === 0)
            return "";

        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     autoplay: true,
        //     autoplaySpeed: 1500,
        //     speed: 1000,
        //     slidesToShow: 10,
        //     slidesToScroll: 1,
        //     arrows: false,
        //     pauseOnHover: true,
        //     responsive: [
        //         {
        //           breakpoint: 1500,
        //           settings: {
        //             slidesToShow: 7,
        //           }
        //         },
        //         {
        //           breakpoint: 1024,
        //           settings: {
        //             slidesToShow: 5,
        //           }
        //         },
        //       {
        //         breakpoint: 600,
        //         settings: {
        //           slidesToShow: 2,
        //         }
        //       },
        //       {
        //         breakpoint: 480,
        //         settings: {
        //           slidesToShow: 1,
        //         }
        //       }
        //       // You can unslick at a given breakpoint now by adding:
        //       // settings: "unslick"
        //       // instead of a settings object
        //     ]
        // };

        // Only show items with specified image
        // const brands = this.state.brands.filter((item) => item.image);

        return (
            <div className="featured-products">
                <h2>Featured Products</h2>

                <div className="items-row">
                    {items}
                </div>

                {/* <Slider {...settings}>
                    {items}
                </Slider> */}
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products.entities,
        cart: state.cart.cart,
    }
};

export default connect(mapStateToProps, null)(FeaturedProducts)