import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import AppContext from '../app/AppContext';
import RafalaApi from 'api/Rafala';
import ProductList from './ProductList';
import ProductDetails from './ProductDetails';
import SubCategoriesList from './SubCategoriesList';
import {withModal} from 'framework/Modal';
import FeaturedProducts from 'components/FeaturedProducts';

import {updateProducts} from "app/modules/ECommerce/_redux/products/productsActions.js";

class ViewMode {
    static get Bulk () { return 0; }
    static get Retail () { return 1; }
}

class ProductsGallery extends React.Component {
    constructor(props) {
        super(props);

        // const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
        // const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
        // console.log(domain, subdomain);

        this.state = {
            categories: null,
            category: null, // Selected category
            brands: null, // Brands for selected category
            showCategoryFilter: true,
            showWineCategoryFilter: true,
            showBrandFilter: true,
            viewMode: (AppContext.IsRetail/*AppContext.Subdomain !== "trade"*/) ? ViewMode.Retail : ViewMode.Bulk
        };
    }

    async componentDidMount() {
        const wineCategoriesResponse = await RafalaApi.WineCategories();
        
        if(wineCategoriesResponse && wineCategoriesResponse.data)
            this.wineCategories = wineCategoriesResponse.data.data;

        const response = await RafalaApi.Categories(this.props.shopId);

        if(response && response.data && response.status === 200) {
            this.categories = response.data.data;
            
            this.setState({ 
                categories: this.categories
            });

            if(this.props.category) {
                const urlSelectedCategory = this.categories.find(c => c.name === this.props.category);

                if(this.props.category === "Home Deliveries")
                    this.setState({
                        viewMode: ViewMode.Retail
                    });

                if(urlSelectedCategory)
                    await this.onSelectCategory(urlSelectedCategory);
            }
        }

        this.unlisten = AppContext.history.listen(async (location, action) => {
            // console.log("on route change", location, action);
            await this.selectCategory(location.pathname);
        });
    }

    componentWillUnmount() {
        if(this.unlisten)
            this.unlisten();
    }

    onAddedToCart = (product) => {
        this.props.modal.hide();
    }

    onClickProduct = (product) => {
        const modalView = <ProductDetails product={product} 
             enableCart={true} onAddedToCart={this.onAddedToCart} />

        this.props.modal.setView(product.name, modalView, true, "product-details-modal");
    }

    selectCategory = async (url) => {
        const urlSelectedCategory = this.categories.find(c => "/"+c.name === url);
        console.log(this.categories, url, urlSelectedCategory);
        if(urlSelectedCategory)
            await this.onSelectCategory(urlSelectedCategory);
    }

    onSelectCategory = async (c) => {
        this.setState({
            category: c
        });

        // If the category is different let's push a new route
        if(AppContext.history.location.pathname !== "/"+c.name) {
            AppContext.history.push(c.name);
            // Reset the filters
            this.subCategoryIds = null;
        }

        this.props.updateProducts(null, null);

        const response = await RafalaApi.Items(this.props.shopId, [c.id]);

        this.props.updateProducts(null, response.data.data);

        const response2 = await RafalaApi.Brands(AppContext.s["project-id"], c.id);
        
        if(response2 && response2.data)
            this.setState({
                brands: response2.data.data
            });

        if(this.props.onSelectCategory)
            this.props.onSelectCategory(c);
    }

    onCheckedItemsChanged = async (items) => {
        // console.log(items);
        const subCategoryIds = items.map(c => c.id);

        this.subCategoryIds = subCategoryIds;

        this.props.updateProducts(null, null);

        const response = await RafalaApi.Items(this.props.shopId, [this.state.category.id], subCategoryIds.length > 0 ? subCategoryIds : null);
        
        this.props.updateProducts(null, response.data.data);
    }

    onCheckedWineTypesChanged = async (items) => {
        console.log(items);
        const wineCategoryIds = items.map(c => c.id);

        this.wineCategoryIds = wineCategoryIds;
        
        this.props.updateProducts(null, null);

        const response = await RafalaApi.Items(this.props.shopId, [this.state.category.id], (this.subCategoryIds && this.subCategoryIds.length > 0) ? this.subCategoryIds : null,
            null, wineCategoryIds.length > 0 ? wineCategoryIds : null);
        
        this.props.updateProducts(null, response.data.data);
    }

    onCheckedBrandsChanged = async (items) => {
        const brandIds = items.map(c => c.id);

        this.brandIds = brandIds;
        
        this.props.updateProducts(null, null);

        const filters = {
            categories: [this.state.category.id],
            sub_categories: (this.subCategoryIds && this.subCategoryIds.length > 0) ? this.subCategoryIds : null,
            wine_categories: (this.wineCategoryIds && this.wineCategoryIds.length > 0) ? this.wineCategoryIds : null,
            brands: (this.brandIds && this.brandIds.length > 0) ? this.brandIds : null,
        };

        const response = await RafalaApi.Items2(this.props.shopId, null, filters);
        
        this.props.updateProducts(null, response.data.data);
    }

    onClickHomeDeliveries = () => {
        this.selectCategory("/Home Deliveries");

        this.setState({
            viewMode: ViewMode.Retail
        });
    }

    onClickBackToBulk = () => {
        this.setState({
            viewMode:  ViewMode.Bulk
        });
    }

    async componentWillReceiveProps(nextProps) {
        // Any time props.email changes, update state.
        if (nextProps.search !== this.props.search) {
            const prevSearch = this.state.search;

            if(this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(async () => {
                this.setState({
                    search: nextProps.search
                });

                if(nextProps.search.length > 2) {
                    this.props.updateProducts(null, null);
                    const response = await RafalaApi.Items2(this.props.shopId, nextProps.search, {}, AppContext.IsRetail);
                    
                    if(response && response.data)
                        this.props.updateProducts(null, response.data.data);
                } else if((prevSearch && prevSearch.length > 2) && nextProps.search.length < 3 && this.state.category) {
                    this.onSelectCategory(this.state.category);
                }
            }, 300);
        }
    }

    render() {
        if(!this.state.categories)
            return AppContext.r["preloader"];

        const {viewMode} = this.state;

        const categories =  
            <div className="menu-categories">
            {
                this.state.categories.filter(x => x.is_retail === (viewMode === ViewMode.Bulk ? 0 : 1)).map((c) => {
                    // Not showing Home Deliveries in the list
                    //if(c.id === AppContext.s['home-deliveries-category-id'])
                    // if(c.is_retail)
                    //     return null;

                    const className = "menu-category" + ((this.state.category && c.id === this.state.category.id) ? " selected" : "");
                        return (
                            <div className={className} onClick={() => this.onSelectCategory(c)}
                                style={{ backgroundImage: "url("+AppContext.s["api-url"] + "/shopitems/categories/"+c.id+"/image/thumb)" }}>
                                <div className="overlay">
                                    <p>{c.name}</p>
                                </div>
                            </div>
                        );
                })
            }
            </div>;

        const {category, search, brands} = this.state; // Selected Category

        const categoryDetails = category ? (
            <Row className="category-details">
                <Col md={3}>
                    <img src={AppContext.s["api-url"] + "/shopitems/categories/"+category.id+"/image"} alt="Category" />
                </Col>

                <Col md={9}>
                    <h2>{category.name}</h2>
                    <p>{category.description}</p>
                </Col>
            </Row>) : "";

        return (
            <>
                {(!search || search.length < 3) && this.state.viewMode === ViewMode.Retail &&
                    <FeaturedProducts onClickProduct={this.onClickProduct} />
                }

                <div id="gallery" className="anchor"></div>
                
                <div className="gallery-content" >
                    {(!search || search.length < 3) && 
                        //(this.state.viewMode === ViewMode.Bulk) &&
                    <>
                        <h2 className="text-center">Browse Our Products {AppContext.s["project-id"] === 1 && this.state.viewMode === ViewMode.Bulk && <span>(trade or bulk only)</span>}</h2>

                        {categories}
                    </>}

                    {/* {this.state.viewMode === ViewMode.Retail &&
                        <Button className="back-to-bulk-button" variant="link" onClick={this.onClickBackToBulk}><i className="fas fa-chevron-left"></i> &nbsp;Browse our bulk products</Button>
                    } */}

                    <div id="galleryItems" className="anchor"></div>

                    { (!search || search.length < 3) && category && 
                    <>
                        <Row className="selected-category-gallery justify-content-center">
                            <div className="desktop-hidden">
                                {categoryDetails}
                            </div>

                            {/* Filters */}
                            <Col md={3} xl={2} className="filters-col">
                                {(category.children && category.children.length > 0) &&
                                    <>
                                        <CardActions>
                                            Category
                                            <IconButton
                                                onClick={() => this.setState({ showCategoryFilter: !this.state.showCategoryFilter })}
                                                aria-expanded={this.state.showCategoryFilter}
                                                aria-label="show more">
                                                {this.state.showCategoryFilter ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                                            </IconButton>
                                        </CardActions>

                                        <Collapse in={this.state.showCategoryFilter} timeout="auto" unmountOnExit>
                                            <SubCategoriesList className="sub-categories-list" categories={category.children} 
                                                onCheckedItemsChanged={this.onCheckedItemsChanged} />
                                        </Collapse>
                                    </> }

                                {category.has_wine &&
                                    <>
                                        <CardActions>
                                            Wine Type
                                            <IconButton
                                                onClick={() => this.setState({ showWineCategoryFilter: !this.state.showWineCategoryFilter })}
                                                aria-expanded={this.state.showWineCategoryFilter}
                                                aria-label="show more">
                                                {this.state.showWineCategoryFilter ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                                            </IconButton>
                                        </CardActions>

                                        <Collapse in={this.state.showWineCategoryFilter} timeout="auto" unmountOnExit>
                                            <SubCategoriesList className="sub-categories-list" categories={this.wineCategories} 
                                                onCheckedItemsChanged={this.onCheckedWineTypesChanged} />
                                        </Collapse>
                                    </> }

                                {(brands && brands.length > 0) &&
                                    <>
                                        <CardActions>
                                            Brands
                                            <IconButton
                                                onClick={() => this.setState({ showBrandFilter: !this.state.showBrandFilter })}
                                                aria-expanded={this.state.showBrandFilter}
                                                aria-label="show more">
                                                {this.state.showBrandFilter ? <ExpandMoreIcon /> : <ExpandLessIcon /> }
                                            </IconButton>
                                        </CardActions>

                                        <Collapse in={this.state.showBrandFilter} timeout="auto" unmountOnExit>
                                            <SubCategoriesList className="sub-categories-list" categories={brands} 
                                                onCheckedItemsChanged={this.onCheckedBrandsChanged} />
                                        </Collapse>
                                    </> }
                            </Col>

                            <Col md={9} xl={10}>
                                <div className="mobile-hidden">
                                    {categoryDetails}
                                </div>

                                <Col style={{padding: 0}}>
                                    <ProductList onClickProduct={this.onClickProduct} enableCart={this.props.enableCart} category={category} />
                                </Col>
                            </Col>
                        </Row>
                    </> }

                    { (search && search.length >= 3) &&
                        <Row className="justify-content-center">
                            <ProductList onClickProduct={this.onClickProduct} enableCart={this.props.enableCart} />
                        </Row> }
                </div>
            </>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateProducts: (totalCount, products) => {
            dispatch(updateProducts(totalCount, products));
        }
    }
};

export default withModal(connect(null, mapDispatchToProps)(ProductsGallery));