import React from 'react';

export function withNavigation(OriginalComponent) {
    return class extends React.Component {
        easeInOutQuad = (t, b, c, d) => {
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        }
    
        smoothScroll = (e, target) => {
            if (window.location.pathname === "/") {
                const isMobile = navigator.userAgent.match(
                    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                    // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                    e.preventDefault();
                    const targetScroll = document.getElementById(target);
                    
                    if(targetScroll)
                        this.scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
                }
            }
        }
    
        scrollGo = (element, to, duration) => {
            var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

            const animateScroll = () => {
                currentTime += increment;
                const val = this.easeInOutQuad(currentTime, start, change, duration);
                element.scrollTop = val;
                if (currentTime < duration) {
                    setTimeout(animateScroll, increment);
                }
            };
            animateScroll();
        }

        render() {
            const newProps = {
                easeInOutQuad: this.easeInOutQuad,
                smoothScroll: this.smoothScroll,
                scrollGo: this.scrollGo
            }

            // Return original component with additional props
            return <OriginalComponent {...this.props} {...newProps} />;
        }
    }
}