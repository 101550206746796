import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Row, Col } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DeleteIcon from '@material-ui/icons/Delete';

import {withModal} from "framework/Modal";
import CheckoutForm from "framework/CheckoutForm";
import AppContext from 'app/AppContext';
import StripeApi from 'api/Stripe';
import ActionButton from 'framework/ActionButton';

class StripeCheckout extends React.Component {
    state = {
        paymentMethods: null,
        paymentMethod: null // Selected Payment Method
    };

    fetchPaymentMethods = async () => {
        // Fetch customer payment methods
        const response = await StripeApi.PaymentMethods(this.props.request.shop_id, (this.props.testMode ? 1 : 0));

        if(response && response.data && response.status === 200) {
            this.setState({
                paymentMethods: response.data.data
            });
        }
        console.log(response);
    }

    async componentDidMount() {
        await this.fetchPaymentMethods();
    }

    deletePaymentMethod = async (p) => {
        // It will show the preloader
        this.setState({
            paymentMethods: null
        });

        this.props.modal.hide();

        const response = await StripeApi.DetachPaymentMethod(this.props.request.shop_id, p.id, this.props.testMode ? 1 : 0);
        console.log(response);

        await this.fetchPaymentMethods();
    }

    confirmDeletePaymentMethod = (p) => {
        this.props.modal.confirm(AppContext.r["delete"] + " " + p.card.brand + " **** **** **** " + p.card.last4, 
            AppContext.r["delete-payment-method-confirm"], async () => await this.deletePaymentMethod(p));
    }

    handleChange = (event) => {
        this.setState({
            paymentMethod: event.target.value
        });
    }

    render() {
        const { paymentMethods, paymentMethod } = this.state;
        const { user, request, amount, onSucceeded } = this.props;

        if(!paymentMethods)
            return AppContext.r["preloader"];

        // If there are not previous payment methods set up a new one
        if(paymentMethods.length === 0)
            return (
                <CheckoutForm 
                    request={request} email={user.email} amount={amount}
                    onSucceeded={onSucceeded} /> );

        // Present payment methods
        return (
            <>
                <FormControl className="payment-methods" component="fieldset">
                    <FormLabel component="legend">Select a card</FormLabel>
                    <RadioGroup className="payment-method" aria-label="payment-methods" 
                        value={paymentMethod} onChange={this.handleChange}>
                        {paymentMethods.map(p => 
                            <Row>
                                <Col xs={10}>
                                    <FormControlLabel value={p.id} control={<Radio />} label={p.card.brand.toUpperCase() + " **** **** **** " + p.card.last4} />
                                </Col>

                                <Col xs={2}>
                                    <ActionButton className="delete-card" action={async () => await this.confirmDeletePaymentMethod(p)}>
                                        <DeleteIcon />
                                    </ActionButton>
                                </Col>
                            </Row>)}
                    </RadioGroup>
                </FormControl>

                {paymentMethod !== undefined && 
                    <Row className="justify-content-center">
                        <Button className="new-card-button" onClick={() => this.setState({paymentMethod: undefined})}>{AppContext.r["pay-by-new-card"]}</Button>
                    </Row> }

                <CheckoutForm paymentMethod={paymentMethod}
                    request={request} email={user.email} amount={amount}
                    onSucceeded={onSucceeded} />
            </>);
    }
}

const InjectedCheckout = (props) => {
    const { user, amount, request, onSucceeded, modal, testMode } = props;

    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <StripeCheckout
                    stripe={stripe} elements={elements} testMode={testMode}
                    modal={modal}
                    user={user} amount={amount} request={request} 
                    onSucceeded={onSucceeded} />
            )}
        </ElementsConsumer>
    );
}

export default withModal(InjectedCheckout);