import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
 
import AppContext from '../app/AppContext';
import ProjectIdsEnum from 'settings/ProjectIdsEnum';
import Counter from '../components/Counter';
// import { addToCart } from "../store/actions/cartActions";
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import { priceTag } from "./Cart/Cart";

class Product extends Component
{
    state = {
        inCart: this.props.inCart
    };

    get cartItem() {
        const { product, cart } = this.props;
        const items = cart.filter(item => item.product.id === product.id);
        
        return (items && items.length === 1) ? items[0] : null;
    }
 
    addToCart = (e) => {
        if(e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }
 
        this.props.addToCart(this.props.product, 1, [], "")
    }

    removeFromCart = (e) => {
        const cartItem = this.cartItem;

        if(cartItem)
            this.props.removeFromCart(cartItem)
    }

    onClickProduct = () => {
        if(this.props.onClickProduct)
            this.props.onClickProduct(this.props.product);
    }

    render() {
        const { product } = this.props;

        let quantity = 0;

        for(let item of this.props.cart)
            if(item.product.id === product.id)
            quantity += item.quantity;
        
        const priceLabel = priceTag(this.props);

        const image = !product.image ? 
            <img className="placeholder" src={AppContext.s["placeholder"]} loading="lazy" alt={product.name} />
            : <img src={AppContext.s["api-url"] + "/shop/items/"+product.id+"/image/thumb"} loading="lazy" alt={product.name} />;
        
        return (
            <figure className="card card-product" onClick={this.onClickProduct}>
                <div className="img-wrap">
                    {image}
                </div>

                <figcaption className="info-wrap">
                    <h4 className="title">{product.name}</h4>
                    {/* <p className="desc">{product.description}</p>*/}
                    
                    {(AppContext.s["project-id"] === ProjectIdsEnum.Enoteca 
                        || product.shop_item_category && product.shop_item_category.is_retail === 1) && priceLabel }
                </figcaption>

                { this.props.enableCart && 
                    <div className="bottom-wrap">
                        <p>Pack of {product.package_quantity} {(product.package_size && product.package_size.length > 0) ? "x " + product.package_size : ""}</p>

                        <>
                            { quantity > 0 ? (
                                <Counter onIncrease={this.addToCart} onDecrease={this.removeFromCart} value={quantity} />
                                ) : <Button onClick={this.addToCart} className="btn-sm add-to-cart-button">{AppContext.r["add-to-cart"]}</Button>}
                        </>
                    </div> }
            </figure>
        )
    }
}
 
const mapStateToProps = (state) => {
    return {
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product, quantity, extras, note) => {
            dispatch(cart.actions.addToCart(product, quantity, extras, note));
        },
        removeFromCart: (cartItem) => {
            console.log("remove", cartItem)
            dispatch(cart.actions.removeFromCart(cartItem.cartItemId));
        }
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(Product);