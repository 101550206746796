import React from 'react';

export default function loadClientResources (resources) {
    const email = "hello@menu.party";

    resources["privacy-policy-doc"] = (
        <div>
            <br />
            <br />
            <br />
            <h1>Privacy Policy</h1>
            <br />
            <br />

            <p className="p1">Uderly Ltd Company UK | VAT No. 338832575 (di seguito, “<b>Titolare</b>”), gestore del sito internet menu.party (di seguito, il “<b>Sito</b>”), in qualità di&nbsp;<b>titolare del trattamento</b>&nbsp;<b>dei dati personali</b>&nbsp;degli utenti del Sito (di seguito, gli “<b>Utenti</b>”) fornisce qui di seguito l’informativa privacy ai sensi dell’art. 13 del Regolamento UE 2016/679 del 27 aprile 2016 (di seguito, “<b>Regolamento</b>” o “<b>Normativa Applicabile</b>”).</p>
            <p className="p2">Il Titolare tiene nella considerazione il diritto alla privacy ed alla protezione dei dati personali dei propri Utenti. Per ogni informazione in relazione alla presente informativa privacy, gli Utenti possono&nbsp;<b>contattare il Titolare</b>&nbsp;in qualsiasi momento, utilizzando le seguenti modalità:</p>
            <ul className="ul1">
            <li className="li3">
                Inviando una raccomandata a/r alla sede legale del Titolare
                </li>
                <li className="li3">
                Inviando un messaggio di posta elettronica all’indirizzo {email}.
                </li>
            </ul>

            <p className="p2">Il Titolare non ha individuato la figura del Responsabile della protezione dei dati (RPD o DPO), in quanto non è soggetto all’obbligo di designazione previsto dall’art. 37 del Regolamento."</p>
            <p className="p2"><b>1. Finalità del trattamento&nbsp;</b></p>
            <p className="p2">I dati personali degli Utenti saranno trattati lecitamente dal Titolare ai sensi dell’art. 6 del Regolamento per le seguenti finalità di trattamento:</p>
            <ul className="ul1">
            <li className="li3">
            <b></b><b>evadere la richiesta dell’Utente</b>: i dati personali degli Utenti sono raccolti e trattati dal Titolare al solo fine di evadere la loro richiesta. I dati dell’Utente raccolti dal Titolare a tale fine includono: il nome, il cognome, email, numero di telefono, nonché tutte le informazioni personali dell’Utente eventualmente e volontariamente pubblicate nella sezione “<i>Messaggio</i>”. Nessun altro trattamento verrà effettuato dal Titolare in relazione ai dati personali degli Utenti. Fermo restando quanto previsto altrove in questa informativa privacy, in nessun caso il Titolare renderà accessibili agli altri Utenti e/o a terzi i dati personali degli Utenti.
            </li>
            </ul>
            <ul className="ul1">
            <li className="li3">
            <b></b><b>finalità amministrativo-contabili</b>, ovvero per svolgere attività di natura organizzativa, amministrativa, finanziaria e contabile, quali attività organizzative interne ed attività funzionali all'adempimento di obblighi contrattuali e precontrattuali;
            </li>
            </ul>
            <ul className="ul1">
            <li className="li4">
            <b></b><b>obblighi di legge</b>, ovvero per adempiere ad obblighi previsti dalla legge, da un’autorità, da un regolamento o dalla normativa europea.
            </li>
            <li className="li3">
            </li></ul>
            <p className="p2">Il conferimento dei dati personali per le finalità di trattamento sopra indicate è facoltativo ma necessario, poiché il mancato conferimento degli stessi comporterà l’impossibilità per l’Utente di effettuare una richiesta al Titolare.</p>
            <p className="p2">I dati personali che sono necessari per il perseguimento delle finalità di trattamento descritte al presente paragrafo 1 sono indicati con un asterisco all’interno del modulo di richiesta.</p>
            <p className="p2"><b>2. Modalità di trattamento e tempi di conservazione dei dati&nbsp;</b></p>
            <p className="p2">Il Titolare tratterà i dati personali degli Utenti mediante strumenti manuali ed informatici, con logiche strettamente correlate alle finalità stesse e, comunque, in modo da garantire la sicurezza e la riservatezza dei dati stessi.</p>
            <p className="p2">I dati personali degli Utenti del Sito saranno conservati per i tempi strettamente necessari ad espletare le finalità primarie illustrate al precedente paragrafo 1, o comunque secondo quanto necessario per la tutela in sede civilistica degli interessi sia degli Utenti che del Titolare.</p>
            <p className="p2"><b>3. Ambito di comunicazione e diffusione dei dati</b></p>
            <p className="p2">Potranno venire a conoscenza dei dati personali degli Utenti i dipendenti e/o collaboratori del Titolare incaricati di gestire il Sito e le richieste degli Utenti. Tali soggetti, che sono stati istruiti in tal senso dal Titolare ai sensi dell’art. 29 del Regolamento, tratteranno i dati dell’Utente esclusivamente per le finalità indicate nella presente informativa e nel rispetto delle previsioni della Normativa Applicabile.</p>
            <p className="p2">Potranno inoltre venire a conoscenza dei dati personali degli Utenti i soggetti terzi che potranno trattare dati personali per conto del Titolare in qualità di “<b>Responsabili Esterni del Trattamento</b>”, quali, a titolo esemplificativo, fornitori di servizi informatici e logistici funzionali all’operatività del Sito, fornitori di servizi in&nbsp;<i>outsourcing</i>&nbsp;o&nbsp;<i>cloud computing</i>, professionisti e consulenti.</p>
            <p className="p2">Gli Utenti hanno il diritto di ottenere una lista degli eventuali responsabili del trattamento nominati dal Titolare, facendone richiesta al Titolare con le modalità indicate al successivo paragrafo 4.</p>
            <p className="p2"><b>4. Diritti degli Interessati</b></p>
            <p className="p2">Gli Utenti potranno esercitare i diritti garantiti loro dalla Normativa Applicabile, contattando il Titolare con le seguenti modalità:</p>
            <ul className="ul1">
            <li className="li3">
            Inviando una raccomandata a/r alla sede legale del Titolare;
            </li>
            <li className="li3">
            Inviando un messaggio di posta elettronica all’indirizzo {email}.
            </li>
            </ul>
            <p className="p2">Ai sensi della Normativa Applicabile, il Titolare informa che gli Utenti hanno il diritto di ottenere l'indicazione (i) dell'origine dei dati personali; (ii) delle finalità e modalità del trattamento; (iii) della logica applicata in caso di trattamento effettuato con l'ausilio di strumenti elettronici; (iv) degli estremi identificativi del titolare e dei responsabili; (v) dei soggetti o delle categorie di soggetti ai quali i dati personali possono essere comunicati o che possono venirne a conoscenza in qualità di responsabili o incaricati.</p>
            <p className="p2">Inoltre, gli Utenti hanno il diritto di ottenere:</p>
            <p className="p2">a) l’<b>accesso</b>, l'<b>aggiornamento</b>, la&nbsp;<b>rettificazione</b>&nbsp;ovvero, quando vi hanno interesse, l'<b>integrazione</b>&nbsp;dei dati;</p>
            <p className="p2">b) la&nbsp;<b>cancellazione</b>, la&nbsp;<b>trasformazione in forma anonima</b>&nbsp;o il&nbsp;<b>blocco</b>&nbsp;dei dati trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;</p>
            <p className="p2">c) l'attestazione che le operazioni di cui alle lettere a) e b) sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato.</p>
            <p className="p2">Inoltre, gli Utenti hanno:</p>
            <p className="p2">a) il diritto di&nbsp;<b>revocare il consenso</b>&nbsp;in qualsiasi momento, qualora il trattamento si basi sul loro consenso;</p>
            <p className="p2">b) il diritto alla&nbsp;<b>portabilità dei dati</b>&nbsp;(diritto di ricevere tutti i dati personali che li riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico), il diritto alla&nbsp;<b>limitazione</b>&nbsp;<b>del</b>&nbsp;<b>trattamento</b>&nbsp;dei dati personali e il&nbsp;<b>diritto alla cancellazione</b>&nbsp;(“diritto all’oblio”);</p>
            <p className="p2">c) il&nbsp;<b>diritto di opporsi</b>:</p>
            <p className="p2">i) in tutto o in parte, per motivi legittimi al trattamento dei dati personali che li riguardano, ancorché pertinenti allo scopo della raccolta.</p>
            <p className="p2">d) qualora ritenessero che il trattamento che li riguarda violi il Regolamento, il diritto di&nbsp;<b>proporre reclamo a un’Autorità di controllo&nbsp;</b>(nello Stato membro in cui risiedono abitualmente, in quello in cui lavorano oppure in quello in cui si è verificata la presunta violazione). L’Autorità di controllo italiana è il<b>&nbsp;Garante per la protezione dei dati personali</b>, con sede in Piazza di Monte Citorio n. 121, 00186 – Roma (<a href="http://www.garanteprivacy.it/">http://www.garanteprivacy.it/</a>).</p>
            <p className="p5">Il Titolare non è responsabile per l’aggiornamento di tutti i link visualizzabili nella presente Informativa, pertanto ogni qualvolta un link non sia funzionante e/o aggiornato, gli Utenti riconoscono ed accettano che dovranno sempre far riferimento al documento e/o sezione dei siti internet richiamati da tale link.&nbsp;</p>
        </div>);

    resources["terms-doc"] = (
        <div>
            <br />
            <br />
            <br />
            <h1>Terms & Conditions</h1>
            <br />
            <br />
            
            <p>Si prega di leggere attentamente i seguenti Termini e Condizioni che elencano le clausole sulla base delle quali menuparty.it fornisce i propri servizi. Gli utenti che rifiutino di accettare i seguenti Termini e Condizioni non sono in grado di registrarsi, e quindi di effettuare ordini attraverso il portale.</p>

            <h2>NORME INTRODUTTIVE</h2>
            • 1.1 Menuparty.it fornisce agli utenti la tecnologia necessaria per poter ordinare al tavolo o da casa ed intrattenere l’utente attraverso giochi a quiz e chat, presso i locali convenzionati col servizio.
            • 1.2 Menuparty.it fornisce agli utenti i riferimenti del locale selezionato per contattarlo in caso di ritardo nella consegna.
            • 1.3 Eventuali reclami verranno presi in considerazione dallo staff se segnalati entro 24 ore dall'ordinazione.
            • 1.4 La navigazione in qualsiasi area del presente Sito denota accettazione dei Termini e Condizioni. Qualora non si intenda accettare i suddetti Termini e Condizioni, si deve abbandonare il Sito immediatamente.
            • 1.5 Menuparty.it ha facoltà di modificare i presenti Termini e Condizioni in qualsiasi momento. I visitatori e utenti del Sito sono tenuti al rispetto delle policy e della disciplina in vigore al momento in cui si effettua l'ordinazione di prodotti.
            • 1.6 Spetta ai visitatori e utenti del portale predisporre quanto necessario per avere accesso al portale stesso. Inoltre tutti coloro che hanno accesso al portale tramite la loro connessione ad Internet siano a conoscenza dei presenti Termini e Condizioni e li rispettino.

            <h2>ORDINAZIONE</h2>
            • 2.1 E' importante controllare le informazioni immesse e correggere eventuali errori prima inoltrare l'ordinazione.
            • 2.2 Inoltrato l'ordine Menuparty.it provvederà a recapitarlo al ristorante e ad informare il cliente sull'accettazione o meno della consegna.
            • 2.4 Una volta che l'ordinazione è stata effettuata non è più possibile annullare l'ordinazione e non si ha diritto ad alcun rimborso.

            <h2>PREZZO E PAGAMENTO</h2>
            • 3.1 I prezzi indicati sul sito vengono indicati direttamente dai ristoranti a Menuparty.it e sono comprensivi di IVA.
            • 3.2 Il portale contiene un vasto numero di menu ed è possibile che alcuni di essi possano recare una indicazione inesatta del prezzo a causa di una mancata segnalazione da parte del ristorante
            • 3.3 In caso di reclamo relativamente alla qualità del cibo o del servizio fornito dai ristoranti del presente portale, eventuali risarcimenti devono essere richiesti direttamente al ristorante. Menuparty.it non è abilitato a concedere rimborsi per conto del ristorante e non risponde in alcun caso in relazione a tali richieste di rimborso. Qualsiasi reclamo deve essere inizialmente presentato al ristorante e, se previste, devono essere seguite le procedure di reclamo proprie del ristorante prima che Menuparty.it possa essere chiamato a rispondere in relazione al reclamo.
            • 3.4 Il pagamento delle ordinazioni deve essere effettuato mediante quanto stabilito prima della conferma dell'ordine stesso.

            <h2>LICENZA</h2>
            • 4.1 E' consentito stampare e scaricare estratti dal Sito, per uso personale, sulla base di quanto segue:
            • 4.1.1 Salvo che sia diversamente previsto, il diritto d'autore e gli altri diritti di proprietà intellettuale relativi al portale ed al materiale in esso pubblicato (ivi incluso, a titolo esemplificativo e non esaustivo, fotografie ed immagini grafiche) sono di proprietà di Menuparty.it o dei soggetti da cui Menuparty.it ha ottenuto la relativa licenza. Le opere suddette sono protette in base alle leggi ed ai trattati in materia di diritto d'autore e tutti i diritti sono riservati. E' vietato l'uso di estratti del portale con modalità diverse da quanto previsto da comma 4.1.
            • 4.1.2 E' necessario riconoscere a Menuparty.it lo status di autore del materiale presente sul Sito.
            • 4.1.4 Non è consentito l'utilizzo di materiale presente sul portale per finalità commerciali, se non espressamente concesso da Menuparty.it
            • 4.2 Ad eccezione di quanto previsto al comma 4.1, il presente portale non può essere riprodotto o archiviato, neanche parzialmente, in alcun altro sito né può essere inserito in alcun sistema per il recupero elettronico delle informazioni, se non espressamente autorizzato da Menuparty.it
            • 4.3 I diritti non esplicitamente concessi nel portale sono riservati.

            <h2>ACCESSO AL SERVIZIO</h2>
            • 5.1 Menuparty.it si adopera per avere il servizio online ventiquattro ore su ventiquattro, ma non è da ritenere responsabile di eventuali cadute o sospensioni del servizio.

            <h2>MATERIALE E CONDOTTA DEI VISITATORI</h2>
            • 6.1 Eventuale materiale diverso dalle informazioni di identificazione personale, trasmesso o inviato tramite post sul portale si considera non riservato e a titolarità libera.
            • 6.2 E' vietato pubblicare, caricare o trasmettere al/dal portale eventuale materiale che:
            • 6.2.1 sia in violazione di eventuali leggi applicabili a livello locale, nazionale o internazionale;
            • 6.2.2 sia illecito o ingannevole;
            • 6.2.3 equivalga a pubblicità non autorizzata; o
            • 6.2.4 contenga virus o qualsiasi altro programma dannoso.
            • 6.3 E' vietato l'uso improprio del portale (inclusa la pirateria informatica).
            • 6.4 E' vietato immettere sul portale commenti o opinioni i quali:
            • 6.4.1 contengano materiale diffamatorio, osceno o offensivo;
            • 6.4.2 promuovano violenza o discriminazione;
            • 6.4.3 violino diritti di proprietà intellettuale di terzi;
            • 6.4.4 siano in violazione di un eventuale obbligo giuridico nei confronti di soggetti terzi (quale, esemplificativamente, un obbligo di fiducia);
            • 6.4.5 promuovano attività illecite o invadano la privacy di soggetti terzi;
            • 6.4.6 diano l'impressione di provenire da Menuparty.it;
            • 6.4.7 vengano utilizzati per impersonare altri o travisare la realtà..
            • 6.5 L'elencazione di attività vietate di cui ai precedenti commi da 6.2 a 6.4 è non esaustiva. Colui che viola una qualsiasi di tali limitazioni è tenuto a risarcire Menuparty.it per qualsiasi costo o danno in cui essa incorra in conseguenza della suddetta violazione.
            • 6.6 Menuparty.it fornirà piena collaborazione ad ogni autorità competente per far rispettare la legge o in relazione ad eventuali ordini giudiziari che richiedano o impongano di rivelare l'identità o l'indirizzo di chiunque invii materiale in violazione delle previsioni di cui ai precedenti commi da 6.2 a 6.4.

            <h2>ESONERO DA RESPONSABILITA'</h2>
            • 7.1 Menuparty.it può apportare qualsiasi modifica al materiale presente sul portale o ai servizi ed ai prezzi in esso descritti, in qualsiasi momento e senza preavviso. Il materiale presente sul portale potrebbe non essere aggiornato, Menuparty.it si impegna ad aggiornarlo in seguito comunicazione da parte del ristorante.
            • 7.2 Menuparty.it fornisce la tecnologia per accedere al sito in maniera sicura, la responsabilità per la sicurezza della password utilizzata per registrarsi portale è del relativo titolare. Menuparty.it non risponde di eventuali transazioni abusive concluse utilizzando il nome e la password di un soggetto registrato.
            • 7.3 ATTENZIONE: menù ed indicazioni sono forniti dai ristoranti, per quanto Menuparty.it si possa impegnare per garantirne la veridicità non è da ritenersi responsabile di eventuali conseguenze causate dalla non veridicità delle informazioni fornite a Menuparty.it dal ristorante.

            <h2>RISOLUZIONE</h2>
            • 8.1 Menuparty.it ha facoltà di cessare o sospendere a sua discrezione il diritto dell'utente di avvalersi del portale ovvero di fare uso dei servizi, mediante comunicazione scritta nel caso in cui:
            • 8.1.1 si ritenga che l'utente abbia immesso sul sito materiale che viola le previsioni di cui ai commi 6.2, 6.3 o 6.4;
            • 8.1.2 In caso di violazione, da parte dell'utente, di qualsiasi altra previsione essenziale dei presenti Termini e Condizioni

            <h2>RESPONSABILITA'</h2>
            • 9.1 Menuparty.it declina qualsiasi responsabilità per eventuali perdite o danni di qualsiasi importo o tipologia che possano derivare ai visitatori ed utenti del portale o a soggetti terzi.
            • 9.2 Il vincolo giuridico contrattuale avente ad oggetto la fornitura e l'acquisto di cibo e bevande intercorre tra il visitatore utente del portale ed il Ristorante al quale si effettua l'ordinazione. Menuparty.it non può assumere alcun impegno riguardo al fatto che il cibo e le bevande ordinate al Ristorante tramite il portale siano di qualità soddisfacente e disconosce qualsiasi dichiarazione di garanzia in tal senso.
            • 9.3 Qualora l'utilizzo effettuato dal visitatore utente del Sito del materiale in esso presente abbia come conseguenza la necessità di effettuare la manutenzione, riparazione o rettifica di dispositivi, software o dati, l'utente del Sito ne assume tutti i relativi costi.ù

            <h2>FORO COMPETENTE</h2>
            Foro competente in via esclusiva a decidere ogni controversia relativa alla conclusione, esistenza, validità, interpretazione, esecuzione, inadempimento, risoluzione derivante dal presente contratto è il Foro di Catania.

            <h2>CLAUSOLE VARIE ED ULTERIORI</h2>
            • 11.1 Tutti i dati personali degli utenti raccolti da Menuparty.it vengono elaborati in conformità alla Policy in materia di Privacy. L'utente del Sito è tenuto ad esaminare la Policy in materia di Privacy.
            • 11.2 Qualora una delle clausole, in tutto o in parte, dei presenti Termini e Condizioni venga dichiarata illegittima, altrimenti invalida o inefficace, da un'autorità o da un tribunale competente, tale clausola o parte di clausola viene eliminata dai Termini e Condizioni e le restanti previsioni degli tessi Termini e Condizioni si applicano come se la previsione lesiva (o la parte lesiva di essa) non fosse mai stata convenuta.
            • 11.3 L'eventuale mancata o tardiva attuazione (in tutto o in parte) di una qualsiasi previsione dei presenti Termini e Condizioni non può essere interpretata come rinuncia dell'una o dell'altra parte a far valere i propri diritti o rimedi.
            • 11.4 L'utente non può cedere alcun diritto o obbligo previsto dai presenti Termini e Condizioni senza il preventivo consenso scritto di Menuparty.it che può cedere qualsiasi diritto o obbligo previsto dai Termini e Condizioni ad una qualsiasi impresa con cui essa si associ per svolgere l'attività imprenditoriale, che essa acquisisca o cui essa sia venduta.

            <h2>STATUS DELL'UTENTE DEL SITO</h2>
            • 12.1 Effettuando un ordine tramite il Sito web, l'utente garantisce:
            • (a) di avere la capacità di agire e concludere contratti giuridicamente vincolanti;

            <h2>COMUNICAZIONI SCRITTE</h2>
            La normativa vigente richiede che alcune informazioni ed avvisi, tra quelli che Menuparty.it fornisce all'utente, debbano essere comunicati per iscritto. Con l'uso del Sito, l'utente accetta che la comunicazione con Menuparty.it avvenga principalmente per via elettronica. L'utente verrà contattato tramite e-mail o gli verranno fornite informazioni tramite post di avvisi sul sito. Per le finalità contrattuali, l'utente accetta tali modalità elettroniche di comunicazione e riconosce che tutti i contratti, gli avvisi, le informazioni e le altre comunicazioni fornite elettronicamente da Menuparty.it rispettano il requisito legale della forma scritta. La presente clausola non intacca i diritti che la legge prevede in favore dei visitatori utenti del portale.

            <h2>EVENTI AL DI FUORI DEL CONTROLLO DI MENUPARTY</h2>
            • 14.1 Menuparty.it non risponde di, e non è tenuta a risarcire, alcun eventuale inadempimento o adempimento tardivo di proprie obbligazioni contrattuali che sia provocato da eventi al di fuori della ragionevole possibilità di controllo da parte di Menuparty.it (Eventi di Forza Maggiore).
            • 14.2 Un Evento di Forza Maggiore include a titolo esemplificativo e non esaustivo quanto segue: (a) scioperi, serrate o altra azione nell'ambito delle relazioni industriali; (b) tumulti civili, sommosse, invasioni, attacchi terroristici o minacce di attacco terroristico, guerre (anche in assenza di apposita dichiarazione) o minacce di guerra nonché preparativi per la guerra; (c) incendi, esplosioni, tempeste, inondazioni, terremoti, crolli, epidemie o altri disastri naturali; (d) impossibilità di servirsi della rete ferroviaria, navale, aerea, del trasporto su gomma o di altri mezzi di trasporto pubblico o privato; (e) impossibilità di servirsi delle reti di comunicazione pubblica o privata; (f) leggi, decreti, normativa, regolamentazione o disciplina restrittiva di un qualsiasi governo.
            • 14.3 L'esecuzione di proprie obbligazioni contrattuali da parte di Menuparty.it si considera sospesa per tutta la durata dell'Evento di Forza Maggiore e Menuparty.it usufruisce di una proroga della durata prevista per l'adempimento pari alla durata del suddetto periodo. Menuparty.it si adopera con ogni sforzo ragionevole per giungere al termine dell'Evento di Forza Maggiore o trovare una soluzione per cui le obbligazioni contrattuali di Menuparty.it possano essere adempiute nonostante l'Evento di Forza Maggiore.

            <h2>SEPARABILITA' DI CLAUSOLE</h2>
            Qualora uno o più termini o condizioni dei presenti Termini e Condizioni vengano dichiarati invalidi, illegittimi o inefficaci, in tutto o per una parte qualsiasi, tali termini, condizioni o previsioni in genere verranno in tale misura separati dai restanti termini, condizioni o previsioni in genere, i quali resteranno validi nella misura massima consentita dalla legge.

            <h2>UNICITA' DELL'ACCORDO</h2>
            I presenti Termini e Condizioni ed ogni altro documento in essi esplicitamente richiamato costituisce l'intero accordo tra Menuparty.it e l'utente e sostituisce tutte le precedenti discussioni, corrispondenza, trattative, accordo precedente, intesa o contratto tra le parti in relazione all'oggetto del contratto.
            L'utente approva specificamente, in conformità a quanto stabilito dagli art. 33 e seguenti D. Lgs. n. 206/2005 Codice del Consumo, le disposizioni dei presenti Termini e Condizioni.
        </div>
    );

    resources["quiz-rules-doc"] = (
        <div>
            <br />
            <br />
            <br />
            <h1>Regolamento Quiz</h1>
            <br />
            <br />

            <p>MenuParty è il primo social menu che integra un gioco a quiz "social" che coinvolge i clienti in giocatori tramite l’utilizzo del proprio smartphone, necessario quindi alla fruizione del gioco.</p> 
                
                <h2>COME FUNZIONA</h2>
                <p>Il gioco è accessibile tramite un indirizzo web fornito anche attraverso un QR CODE e richiede la registrazione all’evento in corso, con un numero massimo di giocatori partecipanti variabile da evento a evento. Durante l’avanzamento del gioco, il giocatore si troverà a rispondere a diverse domande di cultura generale, che possono presentare contenuti multimediali.</p> {/*e che possono essere raggruppate in “manches”*/}
                
                <h2>PUNTEGGIO</h2>
                <p>L’interazione del giocatore comporta l’assegnazione di un punteggio: positivo in caso di risposta corretta o negativo in caso di risposta scorretta.</p>
                 
                <h2>TEMPO</h2>
                <p>Il tempo di risposta influisce sull’assegnazione del punteggio, privilegiando chi risponde correttamente e velocemente. Le tempistiche di gioco possono variare di volta in volta, anche a seconda del contenuto. Una risposta fuori tempo comporterà l’assegnazione del punteggio zero. </p>
                 
                <h2>CLASSIFICA</h2> <p>La classifica finale è data dalla sommatoria dei punteggi acquisiti da ogni giocatore durante ogni manches giocata oppure da un sistema ad “eliminazione” che seleziona dei giocatori scelti per la competizione finale. L’amministrazione si riserva a sua discrezione di scegliere l’algoritmo di calcolo dei punteggi finali di volta in volta.</p>
                 
                <h2>PERSONALIZZAZIONE QUIZ</h2>
                <p>Il social quiz di Menuparty si presta alla personalizzazione delle domande per accrescere la brand awarness dell’attività e l’engagement dei clienti partecipanti che potranno essere premiati come nuovi ambasciatori per aver risposto correttamente alle domande inerenti all’attività.</p>
                 
                <h2>PROIEZIONE QUIZ</h2> <p>L’uso facoltativo di uno o più maxi schermi o proiettori offrirà una migliore e più immersiva esperienza di gioco.</p> 
                
                <h2>CHAT IN REAL TIME</h2>
                <p>Il locale potrà a sua discrezione partecipare alla chat di gruppo comunicando info e promo real time.</p>
        </div>
    );
}