import Axios from 'axios';

import AppContext from '../app/AppContext'
import CoreApi from './Core';

const CancelToken = Axios.CancelToken;
let Source = null;

export default class Rafala {
    static async Categories(shopId = 1) {
        return await Axios.get(AppContext.s["api-url"] + "/shopitems/categories?shopId="+shopId)
            .catch((error) => {
                console.log('error ' + error);
            });
    }
    
    static get _cancelTokenSource () {
        return Axios.CancelToken.source();
    }

    static async Items2(shopId, search, filters = {}, isRetail = 1, highlighted = null) {
        let url = AppContext.s["api-url"] + "/shop/"+shopId+"/items";
        url += "?filters=" + JSON.stringify(filters);
        if(search) {
            url += "&search=" + search;
        }
        url += "&isRetail=" + isRetail;

        if(highlighted)
            url += "&highlighted="+highlighted;

        console.log(url);

        if(Source)
            Source.cancel();

        Source = CancelToken.source();

        return await Axios.get(url, { cancelToken: Source.token })
            .catch((error) => {
                console.log('error ' + error);
                // check if the request was cancelled
                if(Axios.isCancel(error)) {
                    console.log(error.message);
                }
            });
    }

    static async Items(shopId, categoryIds = null, subCategoryIds = null, brandIds = null, wineCategoryIds = null, highlighted = null) {
        // e.g. https://api.rafalauk.com/api/v1/shop/1/items?filters={%22categories%22:[15]}

        let url = AppContext.s["api-url"] + "/shop/"+shopId+"/items";

        const filters = { };

        if(categoryIds && !subCategoryIds)
            filters["categories"] = categoryIds;
        if(subCategoryIds)
            filters["sub_categories"] = subCategoryIds;
        if(brandIds)
            filters["brands"] = brandIds;
        if(wineCategoryIds)
            filters["wine_categories"] = wineCategoryIds;

        url += "?filters=" + JSON.stringify(filters);

        if(highlighted)
            url += "&highlighted="+highlighted;

        console.log(categoryIds, subCategoryIds, wineCategoryIds, url);

        this._cancelTokenSource.cancel();

        return await Axios.get(url, { cancelToken: Rafala._cancelTokenSource.token })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Brands(shopId = 1, categoryId = null) {
        //let url = AppContext.s["api-url"] + "/shop/"+shopId+"/brands";
        let url = AppContext.s["api-url"] + "/shopitems/brands?shopId="+shopId;
        if(categoryId)
            url += ("&categoryId=" + categoryId);


        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async WineCategories() {
        const url = AppContext.s["api-url"] + "/shopitemwinecategories";
        
        return await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async PlaceOrder(shopId, order) {
        // Expected format:
        // {
        //     "items": [
        //         { "shop_item_id": 10, "quantity": 1, "note": "ciao", "extras": "[]" }
        //     ],
        //     "note": "Per favore fate in fretta. No cipolla nel panino"
        // }
        // var config = { headers: { 'Content-Type': 'application/json' } };

        return await Axios.post(AppContext.s["api-url"] + "/shop/"+shopId+"/orders", order)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }

    static async OrderStatuses(shopId) {
        // var config = { headers: { Authorization: Auth.bearer, 'Content-Type': 'application/json' } };
        
        return await Axios.get(AppContext.s["api-url"] + "/shoporders/statuses")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async MyLastOrder() {
        return await Axios.get(AppContext.s["api-url"] + "/mylastorder")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Orders(shopId) {
        return await Axios.get(AppContext.s["api-url"] + "/shop/"+shopId+"/orders")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async CancelOrder(orderId) {
        // var config = { headers: { Authorization: Auth.bearer, 'Content-Type': 'application/json' } };

        return await Axios.delete(AppContext.s["api-url"] + "/shop/orders/" + orderId)
            .catch((error) => {
                console.log('error ' + error);
                return error.response;
            });
    }

    static async Contacts() {
        return await Axios.get(AppContext.s["api-url"] + "/contacts")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async StoreContact(fields) {
        console.log(AppContext.s["api-url"] + "/contacts", fields)
        return await Axios.post(AppContext.s["api-url"] + "/contacts", fields)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async DeleteContact(contactId) {
        return await Axios.delete(AppContext.s["api-url"] + "/contacts/" + contactId)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
            });
    }

    static async Shop(id) {
        return await Axios.get(AppContext.s["api-url"] + "/shops/" + id)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error);
                return error.response;
            });
    }
}