import React from 'react';

import AppContext from 'app/AppContext';
import {PaymentTypesEnum, OrderTypesEnum} from 'components/Cart/Cart';

export default class PaymentInstructions extends React.Component {
    render() {
        // return !this.props.paymentTypeId ? "" :
        //     <div className="payment-desc">
        //         <p>You have chosen to pay with <b>{AppContext.r["payment-types"][this.props.orderTypeId][this.props.paymentTypeId-1]}</b>. If you wish to change your payment selection, please get in touch with us.</p>
            
        //         {this.props.orderTypeId ===2 &&
        //             <p>We deliver in Liverpool and some surrounding areas in the North West and the charges are as follows:
        //             <li>Liverpool L postcodes: FREE</li>
        //             <li>PR postcodes: £5</li>
        //             <li>Wirral/Wallasey/Birkenhead: £5</li></p>}
        //     </div>;

        // console.log(this.props.orderTypeId, this.props.paymentTypeId)

        let message = "";
        
        if(this.props.orderTypeId === OrderTypesEnum.delivery) {
            if(this.props.paymentTypeId === PaymentTypesEnum.bankTransfer) {
                message = <>
                    <p>You have chosen to pay by bank transfer.</p>
                    <p>Please send payment within three days, quoting your surname and order number as a reference to:</p>
                    <p>{AppContext.r["bank-account-details"]}</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.paypal) {
                message = <>
                    <p>You have chosen to pay by PayPal.</p>
                    <p>Please send payment within three days using the email address <b>sales.rafala@gmail.com</b> and quoting your surname and order number as a reference.</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.cashOnDelivery) {
                message = <>
                    <p>You have chosen to pay cash on delivery.</p>
                    <p>You will be updated by email when your order is processed and again when it is on the way. Please make sure you have the correct money as our driver is unlikely to have change.</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.cardOverThePhone) {
                message = <>
                    <p>You have chosen to pay by card over the phone.</p>
                    <p>We will call you the next working day between 9 and 5 unless you stated a specific time at checkout, in which case we will call when you prefer.</p>
                </>
            }
        }

        if(this.props.orderTypeId === OrderTypesEnum.collection) {
            if(this.props.paymentTypeId === PaymentTypesEnum.bankTransfer) {
                message = <>
                    <p>You have chosen to pay by bank transfer.</p>
                    <p>Please send payment within three days, quoting your surname and order number as a reference to:</p>
                    <p>Rafala UK<br />
                    Account number: 47520568<br />
                    Sort code: 30-96-85</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.paypal) {
                message = <>
                    <p>You have chosen to pay by PayPal.</p>
                    <p>Please send payment within three days using the email address <b>sales.rafala@gmail.com</b> and quoting your surname and order number as a reference.</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.cashOnCollection) {
                message = <>
                    <p>You have chosen to pay cash on collection.</p>
                    <p>You will be updated by email when it is ready to collect.</p>
                </>
            }
            if(this.props.paymentTypeId === PaymentTypesEnum.cardOverThePhone) {
                message = <>
                    <p>You have chosen to pay by card over the phone.</p>
                    <p>We will call you the next working day between 9 and 5 unless you stated a specific time at checkout, in which case we will call when you prefer.</p>
                </>
            }
        }

        return message;
    }
}