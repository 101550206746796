import React from 'react';

export default function loadClientResources (resources) {
    resources["shop-full-address"] = "Enoteca, Huntley’s Farm Shop, Whalley Road, Samlesbury, Lancashire, PR5 0UN";
    resources["shop-address1"] = "Huntley’s Farm Shop";
    resources["shop-address2"] = "Whalley Road";
    resources["shop-city"] = "Samlesbury";
    resources["shop-post-code"] = "PR5 0UN";
    resources['our-products-desc'] = "We source from small suppliers in all the major wine regions of the world, allowing us to offer you quality products that may be hard to find elsewhere. Being Italian, we have a broad selection of wine from across Italy and, thanks to our close personal relationship with small wineries, are proud to be able to sell some labels that are exclusive to us. We also believe in supporting small, local businesses so you will find craft beer and gin from producers in Lancashire and the local area.";
    resources["bank-account-details"] = (<>Enoteca Wine Shop<br />
        Account number: 71817144<br />
        Sort code: 05-06-74</>);
    
    resources["payments-help"] = (
        <>
            <p>
                We also supply bars and restaurants, so if you would like to order in bulk or make and enquiry then please feel free to get in touch and we will arrange a meeting.
            </p>

            <h2>Home Delivery</h2>

            <h3>Payment Methods</h3>

            <p>There are four payment options: </p>

            <ul>
                <li>Bank transfer – details at checkout</li>
                <li>Paypal - details at checkout</li>
                <li>Cash on delivery – please have the exact money as the driver won’t have change</li>
                <li>Card over the phone – we will call the next working day between 9am and 5pm to take payment. Write your preferred number in the box and if you can only take calls at a certain time of day let us know there too.</li>
            </ul>

            <p>As soon as we have received the money, we will process the order. If you do not pay within three working days and have not stated cash on delivery, it will be cancelled.</p>
    
            <h3>Delivery and refunds</h3>

            <p>You can order online and collect from the shop in Samlesbury or our
                warehouse in Burscough for free. The shop is open from 10.30am-5pm
                Monday – Saturday and Sunday 11am-4pm. The warehouse is open
                9am-4pm Monday – Friday. You will be notified by email when your
                order is ready for collection.</p>

            <p>We deliver in Liverpool and some surrounding areas in the North West and the charges are as follows:</p>

            <ul>
                <li>Liverpool L postcodes: <strong>FREE</strong></li>
                <li>PR postcodes: £5</li>
                <li>Wirral/Wallasey/Birkenhead: £5</li>
            </ul>

            <p>Our delivery days are:</p>

            <ul>
                <li>Liverpool: Tuesday and Friday between 3pm and 6pm.</li>
                <li>PR postcodes: Wednesday and Thursday between 10am and 5pm.</li>
                <li>Wirral/Wallasey/Birkenhead: Tuesday between 3pm and 6pm.</li>
            </ul>

            <p>Unless you request otherwise, your order will be delivered on the next possible day for your area. If you would like to pre-order for a
                later date, please state this in the box when you confirm. If the time is very inconvenient for you, get in touch and we will try to arrange a suitable alternative.</p>

            <p>If you do not live in any of these areas, we will arrange a courier. For any
                order under 20kg, courier delivery will be charged at a flat rate of £10.</p>

            <h4>Pre-order items</h4>
            <p>
                If any of the items in your basket are listed as pre-order only, we will be in touch with details of a delivery date and everything will arrive at the same time. 
            </p>

            <h4>Refunds and Exchanges</h4>
            <p>
                Please examine your goods on arrival. Any loss or damage must be reported to the driver and recorded on the delivery note. If you are unhappy with a product, you can request a refund/exchange if you send valid proof within 2 working days from the delivery date. Refunds will be processed in 3-5 working days.
            </p>

            <h2>General info</h2>
            
            <p>
                All prices are subject to the price ruling at point of purchase but may be subject to revision at any point. Any offers or promotions are subject to
                availability and may be withdrawn at any time and please note that Enoteca may limit the number of goods supplied to any one customer. 
                If you have any enquires about allergens or need additional info about any of our products, we are happy to help - please get in touch.
            </p>
        </>
    );
}