import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { connect, shallowEqual, useSelector } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AppContext from "app/AppContext";

function CheckoutForm(props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [saveCard, setSaveCard] = useState(true);
    const stripe = useStripe();
    const elements = useElements();

    const { auth } = useSelector(
        ({auth}) => ({
            auth: auth,
        }),
        shallowEqual
    );

    useEffect(() => {
        console.log(props.request);

        if(props.paymentMethod)
            setDisabled(false);

        // Create PaymentIntent as soon as the page loads
        if(!clientSecret) {
            fetchClientSecret();
        }
    }, [ props.paymentMethod ]);

    const fetchClientSecret = (saveCardParam = null) => {
        const data = {
            request: props.request,
            save_card: (saveCardParam === null) ? saveCard : saveCardParam
        };

        if(props.code)
            data.code = props.code;

        console.log(data);

        window.fetch(AppContext.s["api-url"] + "/stripe", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.authToken}`
            },
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setClientSecret(data.clientSecret);
            console.log(data)
        }).catch(function(error) {
            console.log("error", error);

            if (error.response) {
                // Request made and server responded
                console.log(error.response);
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }
        });
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };

    const handleSubmit = async ev => {
        ev.preventDefault();
        setProcessing(true);

        const data = {
            receipt_email: props.email,
            payment_method: {
                card: elements.getElement(CardElement)
            }
        }

        if(props.paymentMethod)
            data.payment_method = props.paymentMethod;

        console.log(data);

        const payload = await stripe.confirmCardPayment(clientSecret, data);

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);

            if(props.onSucceeded)
                props.onSucceeded();
        }
    };
    
    const acceptSaveCard = (
        <FormControlLabel label="Save card for future use" className="agree-button"
            control={
                <Checkbox
                    checked={saveCard}
                    onChange={(event) => {
                        setSaveCard(event.target.checked);
                        fetchClientSecret(event.target.checked);
                    }}
                    name="saveCard" color="primary" />
            } />
    );

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {props.paymentMethod === undefined &&
                <>
                    <p>{AppContext.r["pay-by-card"]}</p>
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />

                    {acceptSaveCard}
                </> }

            <button id="submit" className="checkout-button"
                disabled={processing || disabled || succeeded}>
                <span id="button-text">
                {processing ? <div className="spinner" id="spinner"></div>
                    : <>{AppContext.r["pay"] + " " + AppContext.s['currency'] + " " + props.amount}</> }
                </span>
            </button>

            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>)}

            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment succeeded, see the result in your
                <a href={`https://dashboard.stripe.com/test/payments`}>
                {" "}Stripe dashboard.
                </a> Refresh the page to pay again.
            </p>
        </form>
    );
}
 
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
};

export default connect(mapStateToProps, null)(CheckoutForm);