import React from 'react';
import { Row, Col } from "react-bootstrap";

import Settings from '../settings';
import ProjectIdsEnum from '../settings/ProjectIdsEnum';

import resourcesAuthentication from "./authentication/resources";
import itResourcesAuthentication from "./authentication/resources-it";
import resourcesDocs from "./docs/resources";
import itResourcesDocs from "./docs/resources-it";
import resourcesECommerce from "./e-commerce/resources";
import itResourcesECommerce from "./e-commerce/resources-it";
import resourcesEnoteca from "./enoteca/resources";
import itResourcesEnoteca from "./enoteca/resources-it";

import itResources from './resources-it';

const resources = [];

resources['preloader'] = (
    <div className="animated-preloader">
      <div className="outer">&nbsp;</div>
      <div className="inner">&nbsp;</div>
    </div>);

function enResources() {
    resources['accept-cookies'] = "This website uses cookies to improve your navigation experience, storing your preferences such as your selected language. If you decide to carry on the navigation you accept the usage of cookies.";
    resources['accept'] = "Agreed";
    resources['months'] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    resources['days'] = "Days";
    resources['hours'] = "Hours";
    resources['minutes'] = "Minutes";
    resources['seconds'] = "Seconds";
    resources['phone'] = "Phone";
    resources['email'] = "e-mail";
    resources['name'] = "Name";
    resources['surname'] = "Surname";
    resources['close'] = "Close";
    resources['update'] = "Update";
    resources['follow-us-fb'] = "Like us on Facebook";
    resources['follow-us-ig'] = "Follow us on Instagram";
    resources['contact-us'] = "Contact Us";
    resources['sponsored-by'] = "Sponsored By";
    resources['hosted-by'] = "Hosted by";
    resources['privacy-policy'] = "Privacy Policy";
    resources['terms'] = "Terms & Conditions";
    resources['cancel'] = "Cancel";
    resources['delete'] = "Delete";
    resources['save'] = "Save";
    resources['profile-saved'] = "Your profile has been updated.";
    resources['address'] = "Address";
    resources['insert-new-address'] = "Insert New Address";
    resources['address-alias'] = "Address Alias";
    resources["required-field"] = "Required Field.";
    resources["default-address"] = "Home Address";
    resources["post-code"] = "Post Code";
    resources["city"] = "City";
    resources["country"] = "Country";
    resources["confirm-delete-address"] = "Are you sure you want to delete this address?";
    resources["confirm-delete-address-heading"] = "Delete Address";
    resources["no-address-selected"] = "No address has been selected."
    resources["no-payment-type-selected"] = "No payment method has been selected."
    resources["payment-method"] = "Payment Method"
    resources["purchase-method-order"] = "Order To Table";
    resources["purchase-method-delivery"] = "Delivery";
    resources["purchase-method-collection"] = "Collection";
    resources["purchase-method-quote-request"] = "Request a Quote";
    resources['mobile-phone'] = "Mobile Phone";
    resources['email'] = "e-mail";
    resources['yes'] = "Yes";
    resources['no'] = "No";
    
    resources["access"] = "Access";
    resources["thank-you"] = "Thank you.";

    resources["shop-full-address"] = "Unit 2, Clayton Business Centre, Langley Place, Burscough, Ormskirk L40 8JS";
    resources["shop-address1"] = "Unit 2, Clayton Business Centre";
    resources["shop-address2"] = "Langley Place, Burscough";
    resources["shop-city"] = "Ormskirk";
    resources["shop-post-code"] = "L40 8JS";
    // resources['our-products-desc'] = <>
    //     Our dedicated team of industry experts hold a close relationship with some top-quality wine producers and we are proud to supply our own exclusive labels alongside others from some of the best wineries in Italy. We stock a broad range of quality food and condiments from Italy and beyond - meeting the needs of both commercial establishments and family homes.
    //     </>;
    resources['our-products-desc'] = <>
        We stock a broad range of quality food and condiments from Italy and beyond - meeting the needs of both commercial establishments and family homes and ranging from every day essentials to special treats and products that will wow even the most discerning dinner guests.
        </>; // Our wine selection is available from our sister website, <a href="https://enotecawineshop.co.uk" target="blank">Enoteca wine shop</a>.
    resources["popup-desc1"] = <>
        <a className="brand-logo-link" href="https://www.enotecawineshop.co.uk?from=rafala" target="_blank"><img src="assets/enoteca/images/brand.png" style={{width: "76px", margin: "1em"}} alt="Enoteca" /></a>
        <p>Why don't you check out our wine and spirit selection at our sister site, <a style={{display: "inline"}} href="https://www.enotecawineshop.co.uk?from=rafala" target="_blank">Enoteca</a>, and add some to your home delivery.</p>
    </>;
    resources["popup-desc2"] = <>
        <a className="brand-logo-link" href="https://www.enotecawineshop.co.uk?from=rafala" target="_blank"><img src="assets/enoteca/images/brand.png" style={{width: "76px", margin: "1em"}} alt="Enoteca" /></a>
        <p>If you want to add wine to your home delivery, please check out our sister website Enoteca Wine Shop.</p>
    </>;
    resources["bank-account-details"] = (<>Rafala UK<br />
        Account number: 47520568<br />
        Sort code: 30-96-85</>);
        
    resources["payments-help"] = (
        <>
            <p>
                If you want to become a trade customer, please send an email to sales@rafala.co.uk or call us on 01695 51335. You can also request a quote for the items you are interested in by adding any of the bulk products to your trolley and selecting ‘request quote’.
            </p>

            <h2>Home Delivery</h2>

            <h3>Payment Methods</h3>

            <p>There are three payment options: </p>

            <ul>
                <li>Credit/debit card</li>
                <li>Paypal</li>
                <li>Cash on delivery – please have the exact money as the driver won’t have change</li>
            </ul>

            <p>As soon as we have received the money, we will process the order. If you do not pay within three working days and have not stated cash on delivery, it will be cancelled.</p>
    
            <h3>Delivery charges</h3>

            <p>Our drivers cover Liverpool and the surrounding areas, Preston and Southport. If you live outside of the area, your order will be delivered by courier. The charges are as follows:</p>
            
            <h4>Liverpool</h4>
            <p>L1, L2, L3, L8, L15, L17, L18, L20, L21, L22, L23, L29, L30, L37, L38, L39, L40 <b>FREE DELIVERY</b></p>
            <p>All other L postcodes: <b>£10 delivery</b></p>

            <h4>Wirral (Thursday only)</h4>
            <p>CH41, CH42, CH43, CH44, CH45, CH60 <b>£10 delivery</b></p>
            <p>If you live in another Wirral postcode and we cannot deliver to you, a courier will be
            arranged.</p>

            <h4>Preston/Southport</h4>
            
            <p>PR1, PR2, PR8, PR9 <b>£5 delivery</b></p>
            <p>All other PR postcodes: <b>£10 delivery</b></p>
            
            <h4>Rest of the country</h4>
            <p>If you live outside of our delivery area, a courier will be arranged to deliver your order. The
            courier charge for deliveries up to 15kg (including packaging) is <b>£10</b>. If your order exceeds
            this weight, we will contact you regarding the updated shipping cost.</p>
            <p>Unless you request otherwise, your order will be delivered on the next possible day for your
            area. If you would like to pre-order for a later date, please state this in the box when you
            confirm.</p>
            
            <p><b>PLEASE NOTE: frozen items can be delivered to L postcodes only and someone must be in
            to receive any frozen orders to ensure the product quality is not compromised.</b></p>
            
            <h3>Collection Information</h3>

            <p>You can also collect from our warehouse in Burscough  for free. If you select this option at checkout, we will let you know when your products are ready to be collected. Please take note of the addresses and opening hours:</p>
            {/* or Enoteca at Huntley’s Farm shop, Samlesbury */}
            
            <Row>
                <Col>
                    <p>
                        <b>Rafala UK warehouse:</b><br />
                        Unit 2, Langley Place<br />
                        Clayton Business Centre<br />
                        Burscough Industrial Estate<br />
                        L40 8JS<br />
                        Opening times: Monday – Friday 9am-4pm
                    </p>
                </Col>

                {/* <Col>
                    <p>
                        <b>Enoteca wine shop at Huntley’s:</b><br />
                        Huntley’s Farm Shop<br />
                        Whalley Road<br />
                        Samlesbury<br />
                        Preston<br />
                        PR5 0UN<br />
                        Opening times: Monday – Saturday 10am – 4.30pm, Sunday 11am – 3.45pm
                    </p>
                </Col> */}
            </Row>

            <h3>Pre-order items</h3>
            <p>
                If any of the items in your basket are listed as pre-order only, we will be in touch with details of a delivery date and everything will arrive at the same time.
            </p>

            <h3>Out of Stock/Unavailable Items</h3>
            <p>
                Items that are out of stock or unavailable are listed as such on the website but you can get in touch if you would like to know when we are expecting them again. In the unlikely event that a product has gone out of stock but you have ordered before the website has been updated, you have options that we will discuss with you.
            </p>
            <ol>
                <li>We will give you a full refund for the product/s.</li>
                <li>We will offer you an alternative product.</li>
                <li>We will let you know when the product will be back in stock and deliver it to you then if this is convenient.</li>
            </ol>
            
            <h3>Refunds and Exchanges</h3>
            <p>
                Please examine your goods on arrival. Any loss or damage must be reported to the driver and recorded on the delivery note. If you are unhappy with a product, you can request a refund/exchange if you send valid proof within 2 working days from the delivery date. Refunds will be processed in 3-5 working days.
            </p>

            <h2>Trade deliveries</h2>

            <h3>Areas and charges</h3>

            <p>
                We cover all Liverpool areas, Preston, Southport, The South Lakes, Chorley, Wigan and can arrange delivery in other nearby areas too in accordance with our schedule. Delivery is free with a minimum order of £100 and is limited to 1 or 2 per week, depending on the area and season. Please get in touch to discuss and arrange specific details.
            </p>

            <h2>General info</h2>
            
            <p>
                All prices are subject to the price ruling at point of purchase but may be subject to revision at any point. Any offers or promotions are subject to availability and may be withdrawn at any time and please note that Rafala UK may limit the number of goods supplied to any one customer.
                If you have any enquires about allergens or need additional info about any of our products, we are happy to help - please get in touch.
            </p>
        </>
    );
}

export function loadResources() {
    if(Settings["language"] === "it") {
        itResources(resources);

        itResourcesAuthentication(resources);
        itResourcesDocs(resources);
        itResourcesECommerce(resources);
        // itResourcesEnoteca(resources);
    } else {
        enResources();
    
        resourcesAuthentication(resources);
        resourcesDocs(resources);
        resourcesECommerce(resources);
        // resourcesEnoteca(resources);
    }

    if(Settings['project-id'] === ProjectIdsEnum.Enoteca)
        if(Settings["language"] === "it") {
            itResourcesEnoteca(resources);
        } else {
            resourcesEnoteca(resources);
        }
}

// loadResources();

export default resources;