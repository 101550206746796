import React from 'react';
import { Container, Form, Col, Row } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import { connect } from "react-redux";
import * as Yup from 'yup';
import { FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Switch } from 'formik-material-ui';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';

import * as auth from "../_redux/authRedux";
import AppContext from 'app/AppContext';
// import AuthApi from 'api/Auth';
import Core from 'api/Core';
import FormImage from 'framework/FormImage';
import ChangePassword from '../partials/ChangePassword';
import DeleteAccount from '../partials/DeleteAccount';
import { withModal } from 'framework/Modal';
import withOverlay from 'framework/withOverlay';

Yup.addMethod(Yup.string, "phone", function(messageError = AppContext.r['phone-not-valid']) {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    
    return this.test('phone', messageError, value => {//return false;
        if(value && value.substr(0,1) === "+")
            value = value.substr(1, value.length-1);

        if (value && value.length > 0)
            return phoneRegExp.test(value);

        return true;
    })
})

class MyAccount extends React.Component {
    _updateDelayMs = 500;
    _updateTimeout = null;

    state = {}

    validationSchema = Yup.object().shape({
        name: Yup.string().required(AppContext.r["required-field"]),
        surname: Yup.string().required(AppContext.r["required-field"]),
        email: Yup.string().email(),
        allow_newsletter: Yup.bool(),
        //mobile_phone: Yup.string().notRequired().matches(AppContext.s['phone-regex'], AppContext.r['phone-not-valid']),
        mobile_phone: Yup.string().phone().nullable()
    });

    formImageRef = React.createRef();

    async componentDidMount() {
        if(this.props.updateLayout)
            this.props.updateLayout();
    }

    onChange = (values) => {
        // if(!this.props.auth.user)
        //     return;

        // // Validation
        // if(this._updateTimeout !== null) {
        //     clearTimeout(this._updateTimeout);
        //     this._updateTimeout = null;
        // }

        // const t = this;

        // this._updateTimeout = setTimeout(function () {
        //     t.validationSchema.isValid(values).then(async function(valid) {
        //         // console.log(t.validationSchema, values, valid);

        //         if(valid) {
        //             // Update user
        //             const user = {
        //                 id: t.props.auth.user.id,
        //                 ...values
        //             };
        //             delete user["email"];

        //             // console.log(user);

        //             await Core.EditUser(t.props.auth.user.id, user);

        //             const updatedUser = { ...t.props.auth.user };

        //             for(var key in user) {
        //                 updatedUser[key] = user[key];
        //                 // console.log(key + " = " + user[key] )
        //             }

        //             // TODO: Check if response is ok
                    
        //             // Update cached user data
        //             t.props.fulfillUser(updatedUser);
        //         }
        //     });
        // }, this._updateDelayMs);

        // return {}; // This is required by Formik
    }

    updateUser = async (values) => {
        this.props.overlay.setView("");
        this.props.overlay.showBusy();
        
        // Update user
        const user = {
            id: this.props.auth.user.id,
            ...values
        };
        delete user["email"];

        // console.log(user);

        await Core.EditUser(this.props.auth.user.id, user);

        const updatedUser = { ...this.props.auth.user };

        for(var key in user) {
            updatedUser[key] = user[key];
            // console.log(key + " = " + user[key] )
        }

        // TODO: Check if response is ok
        
        // Update cached user data
        this.props.fulfillUser(updatedUser);

        const view = (
            <>
                <i className="far fa-check-circle icon"></i>
                <p className="message">{AppContext.r["profile-saved"]}</p>
            </>);

        this.props.overlay.setView(view, true, true);
    }

    onImageFileChanged = async (file, attributeName = 'image') => {
        console.log(AppContext.s["api-url"] + "/users/media")
        const response = await Core.uploadImageFile(file, AppContext.s["api-url"] + "/users/media", attributeName);
        // await Core.EditUser(user.id, user);
        console.log(response);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                image_name: file.name
            };

            item[attributeName] = response.data.name;
            // console.log(item);

            await Core.EditUser(this.props.auth.user.id, item);
            // await t.update(item, attributeName === 'image', attributeName === 'cover');
        }
    }

    onDeleteImage = async () => {
        await Core.EditUser(this.props.auth.user.id, {image: "-1"});
        
        this.formImageRef.current.resetImage();
    }
    
    onChangePassword = () => {
        this.props.modal.setView(<><i className="fas fa-key"></i> {AppContext.r["change-password"]}</>, <ChangePassword modal={this.props.modal} onPasswordChanged={this.onPasswordChanged} />, true);
    }

    onPasswordChanged = (response) => {
        console.log(response);
        this.props.modal.hide();
    }

    onDeleteAccount = () => {
        this.props.modal.setView(<><i className="fas fa-user-alt-slash"></i> {AppContext.r["delete-account"]}</>, <DeleteAccount modal={this.props.modal} onAccountDeleted={this.onAccountDeleted} />, true);
    }

    onAccountDeleted = async (response) => {
        // alert(AppContext.r['account-deleted']);
        //console.log(response);
        //AppContext.Auth.logout();

        // this.setState({
        //     redirectTo: "/",
        // });
    }

    render() {
        let view = <p className="text-center">{AppContext.r["must-be-logged"]}</p>;

        if(this.props.auth.user) {
            const t = this;

            view = (
                <Formik validate={this.onChange} 
                    validationSchema={this.validationSchema} enableReinitialize
                    initialValues={{
                        name: this.props.auth.user.name,
                        surname: this.props.auth.user.surname,
                        mobile_phone: this.props.auth.user.mobile_phone,
                        email: this.props.auth.user.email,
                        allow_newsletter: this.props.auth.user.allow_newsletter ? true : false
                    }}
                    onSubmit={fields => {
                        console.log(fields);
                        this.updateUser(fields);
                    }}
                    render={({ errors, handleChange, touched, initialValues, values, handleSubmit }) => (
                        <FormikForm onSubmit={handleSubmit}>
                            <Row className="justify-content-md-center">
                                <Form.Group as={Col} md="auto">
                                    <FormImage ref={t.formImageRef}
                                        rounded type="avatar" height={192} maxWidth={192}
                                        imageUrl={(this.props.auth.user) ? AppContext.s['api-url'] + "/users/" + this.props.auth.user.id + "/image" : ""}
                                        onDelete={t.onDeleteImage}
                                        onImageFileChanged={t.onImageFileChanged} />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["name"]}</Form.Label>
                                    <Field name="name" type="text" onChange={handleChange}
                                        placeholder={AppContext.r["name"]} className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                    <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["surname"]}</Form.Label>
                                    <Field name="surname" type="text" onChange={handleChange}
                                        placeholder={AppContext.r["surname"]} className={'form-control' + (errors.surname && touched.surname ? ' is-invalid' : '')} />
                                    <ErrorMessage name="surname" component="div" className="invalid-feedback" />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["mobile-phone"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["mobile-phone"]} name="mobile_phone"
                                                value={values.mobile_phone} onChange={handleChange}
                                                isValid={touched.mobile_phone && !errors.mobile_phone} isInvalid={!!errors.mobile_phone} />
                                    <ErrorMessage name="mobile_phone" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["email"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["email"]} name="email" disabled
                                                value={values.email} onChange={handleChange}
                                                isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group as={Col} md="6">
                                    {/* <div className="checkbox-wrapper">-

                                        <Field name="allow_newsletter" type="checkbox" className={"checkbox" + (errors.allow_newsletter && touched.allow_newsletter ? ' is-invalid' : '')} 
                                            value={values.allow_newsletter} onChange={(e) => {
                                                console.log(e.target.value, values);
                                                //setFieldValue("allow_newsletter", e.target.value);
                                                //handleChange();
                                              }} />
                                        <label htmlFor="allow_newsletter">{AppContext.r['allow-newsletter']}</label>
                                    </div> */}

                                    <Box margin={1} className="accept-data-treatment-box">
                                        <FormControlLabel control={<Field component={Switch} type="checkbox" name="allow_newsletter" disabled={false} />} 
                                            label={AppContext.r['allow-newsletter']} />
                                    </Box>
                                            {/* onChange={handleChange} */}
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="display-flex justify-content-center">
                                    <Button type="submit" variant="contained" color="primary" size="normal" startIcon={<SaveIcon />}>{AppContext.r["save"]}</Button>
                                </Form.Group>
                            </Row>

                            <hr />

                            <Row>
                                <Button onClick={this.onChangePassword}>
                                    <i className="fas fa-key"></i> &nbsp;{AppContext.r['change-password']}
                                </Button>

                                <Button onClick={this.onDeleteAccount}>
                                    <i className="fas fa-user-alt-slash"></i> &nbsp;{AppContext.r['delete-account']}
                                </Button>

                                <br /><br />
                            </Row>
                        </FormikForm>
                    )} />
                );
        }

        return (
            <Container className="page">
                <h2 className="text-center">{AppContext.r["my-account"]}</h2>

                <div className="myaccount">
                    {view}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, auth.actions)(withModal(withOverlay(MyAccount)));