import AppContext from 'app/AppContext';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Product from './Product';

class ProductList extends Component {
    addToCart = (product) => {
        console.log(product);
        this.props.addToCart(product);
    }

    appendItemsJsx(items, a) {
        if(items) {
            const photoItems = [];
            const listItems = [];

            for(var product of items) {
                const productJsx = <Product key={product.id} product={product} onClickProduct={this.props.onClickProduct}
                 addToCart={this.addToCart} enableCart={this.props.enableCart}
                     inCart={this.props.cart.length > 0 && this.props.cart.filter(e => e.product.id === product.id).length > 0 } />;

                if(product.image)
                    photoItems.push(productJsx);
                else
                    listItems.push(productJsx);
            }

            a.push(<div className="photo-items">{photoItems}</div>);
            a.push(<div className="list-items">{listItems}</div>);
        }
    }

    render() {
        const {products} = this.props;

       if(!products)
            return (
                <div className="preloader-box">
                    {AppContext.r["preloader"]}
                </div>);

        // console.log(products);

        const items = !products ? [] : products.map(product => {
            return (<Product key={product.id} product={product} onClickProduct={this.props.onClickProduct}
                addToCart={this.addToCart} enableCart={true}
                inCart={this.props.cart.length > 0 && this.props.cart.filter(e => e.product.id === product.id).length > 0 } />);
        });

//        this.appendItemsJsx(products, items);// products.map(p => (<div className="photo-items">{photoItems}</div>))

        // const items = [];

        // for(let i = 0; products && i < products.length; i++) {
        //     const category = products[i];

        //     if(category.items.length === 0 && category.children.length === 0)
        //         continue;

        //     items.push(<div key={i + "div"} id={category.name.replace(/ /g,'')} className="menu-anchor"></div>);
        //     items.push(<h2 key={i + "h2"}>{category.name}</h2>);

        //     this.appendItemsJsx(category.items, items);

        //     if(category.children)
        //         for(let subCategory of category.children) {
        //             items.push(<h3>{subCategory.name}</h3>);

        //             this.appendItemsJsx(subCategory.sub_items, items);
        //         }

        //     items.push(<hr key={i + "hr"} />);
        // }

        return (
            <div className="product-list">
                { items.length > 0 ? items : <h4>No items have been found.</h4> }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.products.entities,
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

export default connect(mapStateToProps, null)(ProductList)