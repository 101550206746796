import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Maps from "framework/Map";

import Header from "components/Header/Header.js";
import HeaderLinks from 'app/HeaderLinks.js';
import AppContext from 'app/AppContext.js';
import Logo from 'components/Logo';

export default class Contact extends React.Component {
    componentDidMount() {
        if(this.props.updateLayout)
            this.props.updateLayout();
    }
    
    render() {
        return (
            <div>
                <Header brand={<Logo />} fixed color="primary" links={<HeaderLinks />} />

                <Container fluid className="page-container">
                    <Row className="justify-content-md-center contact-us">
                        <Col md={4} className="image-box">
                            <Logo size="big" /> 
                        </Col>

                        <Col md={8} className="">
                            <h2>Contact Us</h2>
                            {/* or fill in the contact form above */}
                            <p>If you're looking for more information about a particular product, product availability or delivery of an order please email us so that your inquiry can be passed on to the best person to deal with it and we can respond as quickly as possible.</p>
                            <h3>Contact us today!</h3>
                            <p><a href={"mailto:" + AppContext.s["phone"]}><i className="fas fa-phone"></i> {AppContext.s["phone"]}</a></p>
                            <p><a href={"tel:" + AppContext.s["email"]}><i className="far fa-envelope"></i> {AppContext.s["email"]}</a></p>

                            <h3>Where we are</h3>
                            <p>{AppContext.r["shop-full-address"]}</p>
                            
                            <Maps className="contact-map" location={{
                                    address1: AppContext.r["shop-address1"],
                                    address2: AppContext.r["shop-address2"],
                                    city: AppContext.r["shop-city"],
                                    post_code: AppContext.r["shop-post-code"],
                                    country: { name: "United Kingdom" }
                                }}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}