import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

// import { addToCart } from "../store/actions/cartActions";
import ProjectIdsEnum from 'settings/ProjectIdsEnum';
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import AppContext from '../app/AppContext';
import ToggleButton from '../framework/ToggleButton';
import { priceTag } from './Cart/Cart';

var HtmlToReactParser = require('html-to-react').Parser;

class ProductDetails extends React.Component {
    state = {
        extras: [],
        note: ""
    }

    componentDidMount() {
        const htmlToReactParser = new HtmlToReactParser();
        const reactElement = htmlToReactParser.parse(this.props.product.description);

        ReactDOM.render(
            reactElement,
            document.getElementById('ProductDescription')
        );
    }

    addToCart = (e) => {
        if(e && e.preventDefault) {
            e.preventDefault();
            e.stopPropagation();
        }

        console.log(this.state);
 
        this.props.addToCart(this.props.product, 1, this.state.extras, this.state.note)

        if(this.props.onAddedToCart)
            this.props.onAddedToCart(this.props.product, 1, this.state.extras, this.state.note);
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onToggleExtra = (extra, value) => {
        console.log(extra, value);

        const extras = this.state.extras;

        if(value)
            extras.push(extra);
        else
            for(let i in extras)
                if(extras[i].id === extra.id)
                    extras.splice(i, 1);

        this.setState({
            extras: extras
        })
    }

    render() {
        const { product } = this.props;

        this.props.cartUpdated();

        let totalExtras = 0;
        
        for(let extra of this.state.extras) {
            totalExtras += extra.price;
        }

        let priceLabel = priceTag(this.props);
        
        // const total = product.price + totalExtras;

        // if(AppContext.s["project-id"] === ProjectIdsEnum.Enoteca 
        //     || product.shop_item_category && product.shop_item_category.id === 144)
        //     if(product.percent_discount > 0)
        //         priceLabel = (
        //             <p className="price text-center">
        //                 <span className="line-through">{total + " " + AppContext.s["currency"]}</span> &nbsp;
        //                 <span>{total - total * product.percent_discount / 100 + " " + AppContext.s["currency"]}</span> &nbsp;
        //                 <span>({product.percent_discount}%)</span>
        //             </p>);
        //     else
        //         priceLabel = <p className="price text-center">{total + " " + AppContext.s["currency"]}</p>;

        // const extras = category.extras.map(x => 
        //     <ToggleButton on={<div><span className="name">{x.name}</span><span className="price">{x.price} {AppContext.s['currency']}</span></div>}
        //                     off={<div><span className="name">{x.name}</span><span className="price">{x.price} {AppContext.s['currency']}</span></div>} 
        //                     onToggle={(value) => this.onToggleExtra(x, value) }/>);
        
        const ingredients = (product.ingredients && product.ingredients.length > 0) ?
            (<div>
                <h2>{AppContext.r['ingredients']}</h2>
                <p className="ingredients">{product.ingredients}</p>
            </div>) : "";

        // Product Flags
        const flags = [];
        if(product.vegan) flags.push(<span key="vegan-flag" className="product-flag">{AppContext.r['vegan']}</span>);
        else if(product.vegetarian) flags.push(<span key="vegetarian-flag" className="product-flag">{AppContext.r['vegetarian']}</span>);
        if(product.gluten_free) flags.push(<span key="gluten-free-flag" className="product-flag">{AppContext.r['gluten-free']}</span>);
        
        const image = !product.image ? "" : <div className="img-wrap" style={{ backgroundImage: "url("+AppContext.s["api-url"] + "/shop/items/"+product.id+"/image)" }} />;

        return (
            <figure className="card card-product details" onClick={this.onClickProduct}>
                {image}

                <figcaption className="info-wrap">
                    <h3 className="title">{product.name}</h3>
                    <h4 className="text-center">Pack of {product.package_quantity} x {product.package_size}</h4>

                    <p className="desc" id="ProductDescription">{product.description}</p>
                    {ingredients}
                    <p className="flags">{flags}</p>
                </figcaption>

                {/* <div className="extras-wrap" style={{display: (extras.length > 0) ? "block" : "none"}}>
                    <h4 className="extras">{AppContext.r["extras"]}</h4>
                    {extras}
                    {/* <p className="extras-total">{totalExtras} {AppContext.s['currency']}</p> */}
                {/* </div> */}
                {product.shop_item_availability_state &&
                    <p className="text-center"><strong>{product.shop_item_availability_state.name}</strong></p> }
                
                {(AppContext.s["project-id"] === ProjectIdsEnum.Enoteca 
                        || (product.shop_item_category && product.shop_item_category.is_retail === 1)) && priceLabel }

                <div style={{display: this.props.enableCart ? "block" : "none"}}>
                    <div className="bottom-wrap">
                        <Form.Control type="text" name="note" placeholder="Note"
                            value={this.state.note}  onChange={e => this.handleChange(e)} />
                        <Button onClick={this.addToCart} className="btn btn-sm btn-primary float-right add-to-cart-button">{AppContext.r["add-to-cart"]} </Button>
                    </div>
                </div>
            </figure>);
    }
}
 
const mapStateToProps = (state) => {
    return {
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product, quantity, extras, note) => {
            dispatch(cart.actions.addToCart(product, quantity, extras, note));
        }
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);