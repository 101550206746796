import React, { useEffect } from "react";
import ScrollIntoView from 'react-scroll-into-view';
import Carousel from "react-slick";
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// core components
import Tooltip from "@material-ui/core/Tooltip";
import Fab from '@material-ui/core/Fab';
import {Row, Col} from 'react-bootstrap';
import Logo from "components/Logo.js";

import galleryStyle from "./galleryStyle.js";

import { withNavigation } from './withNavigation';

import AppContext from 'app/AppContext';

const useStyles = makeStyles(galleryStyle);

var HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

function Header(props, { ...rest }) {
    // useEffect(() => {
    //     const reactElement = htmlToReactParser.parse(AppContext.r["about-us-desc"]);
    //     ReactDOM.render(
    //         reactElement,
    //         document.getElementById('AboutUs')
    //     );
    // }, [])

    const classes = useStyles();
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 10000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: false
    };

    const LightTooltip = withStyles((theme) => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
        }))(Tooltip);

    // <a href="#menu" className={"chevron-down " + classes.scrollDownButton} onClick={(e) => props.smoothScroll(e, "ourProducts")}>
    const scrollDownButton = (
        <ScrollIntoView selector={"#ourProducts"}>
            <a className={"chevron-down "}>
                <LightTooltip title="Browse Our Products" placement="bottom">
                    <Fab style={{color: "white"}}>
                        <ExpandMoreIcon className={classes.extendedIcon} />
                    </Fab>
                </LightTooltip>
            </a>
        </ScrollIntoView>
    );
    
    return (
        // we've set the className to cd-section so we can make smooth scroll to it
        <div className="cd-section" {...rest}>
            <div>
                {/* <MaterialHeader brand={<Logo />} fixed color="transparent"
                    changeColorOnScroll={{
                        height: 180,
                        color: "primary"
                    }}
                    links={<HeaderLinks />} /> */}

                <Carousel {...settings}>
                    <div className="header-slide">
                        <div className={"page-header " + classes.pageHeader} style={{ backgroundImage: `url("${AppContext.s["cover-path"]}")` }}>
                            <div className="content">
                                <Row>
                                    <Col>
                                        <h1 className="banner-title">{AppContext.s["about-us"]}</h1>
                                        <h4 className="banner-subtitle">{AppContext.s["sub-title"]}</h4>
            
                                        <Logo size="big" />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <span className="banner-desc">
                                            {AppContext.s["about-us-desc"]}
                                        </span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                            </div>

                            <div className="footer">
                                {scrollDownButton}
                                {props.footer && props.footer}
                            </div>
                        </div>
                    </div>
{/* 
                    <div className="header-slide">
                        <div className={classes.pageHeader} style={{ backgroundImage: `url("${dg2}")` }}>
                            <div className={classes.container}>
                                <GridContainer>
                                    <GridItem xs={12} sm={8} md={8}
                                        className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
                                        <h1 className={classes.title}>{AppContext.s["project-name"]}</h1>
                                        <h6 className="tag-line">{AppContext.r["payoff"]}</h6>
                                        
                                        <Logo size="big" />
                                        
                                        <br />
                                        <h6>Connect with us on:</h6>
                                        <div>
                                            <Button color="white" simple size="lg" justIcon target="_blank"
                                                href={AppContext.s["twitter-page-url"]}>
                                                <i className="fab fa-twitter" />
                                            </Button>

                                            <Button color="white" simple size="lg" justIcon target="_blank"
                                                href={AppContext.s["facebook-page-url"]}>
                                                <i className="fab fa-facebook-f" />
                                            </Button>

                                            <Button color="white" simple size="lg" justIcon target="_blank"
                                                href={AppContext.s["instagram-page-url"]}>
                                                <i className="fab fa-instagram" />
                                            </Button>
                                        </div>
                                        <br />
                                        {menuButton}
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
            </div>
        </div>
  );
}

export default withNavigation(Header); 
