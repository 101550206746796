import settings from "../settings";
import resources from "../resources/resources";

export default class AppContext {
    static get s() { return settings; }
    static get r() { return resources; }

    static history = null;

    static enableCart = true;
    static IsRetail = true;
}