// Map v1.1

// https://scotch.io/tutorials/react-apps-with-the-google-maps-api-and-google-maps-react

import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

import AppContext from 'app/AppContext';

const mapStyles = {
    height: '250px',
    position: "relative",
    marginBottom: "1em"
};

export class Maps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},         //Shows the infoWindow to the selected place upon a marker
            coords: null
        };
        
        this.location = this.props.location;
        this.key = AppContext.s["google-maps-key"];
    }

    componentDidMount() {
        if(this.location != null) {
            var address = this.location.address1 +  ", " + this.location.address2 + ", " + this.location.city + ", " + this.location.post_code + ", " + this.location.country.name;
            address = address.replace(/\s/g, '+');
                
            fetch("https://maps.googleapis.com/maps/api/geocode/json?key="+this.key+"&address=" + address)
            .then(res => res.json())
            .then(
                (o) => {
                    if(o["results"][0] != null && typeof(o["results"][0]) !== 'undefined') {
                        var lat = o["results"][0]["geometry"]["location"]["lat"];
                        var lng = o["results"][0]["geometry"]["location"]["lng"];
                        
                        this.setState({
                            coords: {"lat": lat, "lng": lng}
                        })
                    }
                },
                (error) => {
                    this.setState({
                        event: null
                    });
                }
            )
        }
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onClose = props => {
        if (this.state.showingInfoWindow)
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
    }

    openMaps = () => {
        const a = {
            action: "maps",
            data: JSON.stringify(this.state.coords)
        };

        if(window.invokeCSCode)
            window.invokeCSCode(JSON.stringify(a));
    }

    render() {
        if(this.state.coords == null)
            return "";

        return (
            <Map
                className={this.props.className ? this.props.className : ""}
                google={this.props.google}
                zoom={14}
                containerStyle={mapStyles}
                style={mapStyles}
                initialCenter={this.state.coords}
                onClick={this.openMaps}>
                <Marker
                    onClick={this.onMarkerClick}
                    name={'Kenyatta International Convention Centre'}
                    animation="google.maps.Animation.DROP" />

                <InfoWindow
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                    onClose={this.onClose} >
                    <div id="bodyContent" className="map-info-window">
                        <h3>{this.location.name}</h3>
                        <p>{this.location.address1}</p>
                        <p>{this.location.address2}</p>
                        <p>{this.location.post_code}</p>
                        <p>{this.location.city}</p>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBBduueLb6PfFIa5Z811RxdusHYeP3-u-I'
})(Maps);