/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { red, green, orange, purple } from '@material-ui/core/colors';

// import {Layout} from "../_metronic/layout";
import Layout from "./Layout";
import BasePage from "./BasePage";
import AppContext from './AppContext';
// import { Logout, AuthPage } from "./modules/Auth";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
    const theme = createMuiTheme({
        status: {
            danger: orange[500],
        },
        palette: {
          primary: {
            main: red[500],
          },
          secondary: {
            main: AppContext.s["secondary-color"],
          },
        }
    });

    // const {isAuthorized} = useSelector(
    //     ({auth}) => ({
    //         isAuthorized: auth.user != null,
    //     }),
    //     shallowEqual
    // );

    return (
        <Switch>
            {/* {!isAuthorized ? (
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                <Redirect from="/auth" to="/"/>
            )} */}

            {/* <Route path="/error" component={ErrorsPage}/> */}
            {/* <Route path="/logout" component={Logout}/> */}

            {/*Redirect to `/auth` when user is not authorized*/}
            {/* {!isAuthorized ? (
                <Redirect to="/auth/login"/>
            ) : ( */}
            <ThemeProvider theme={theme}>
                    <BasePage/>
            </ThemeProvider>
             {/*)} */}
        </Switch>
    );
}
