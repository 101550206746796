import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import * as cart from "../app/modules/ECommerce/_redux/cart/cartRedux";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  products: productsSlice.reducer,
  cart: cart.reducer,
  //customers: customersSlice.reducer,
  //remarks: remarksSlice.reducer,
  //specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
