import React from "react";
import { Button, Form, Col, Row } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import RafalaApi from "api/Rafala";
import RemoteSelect from 'framework/RemoteSelect';
import withOverlay from "framework/withOverlay";
import {withModal} from "framework/Modal";

class Contacts extends React.Component {
    state = {
        contacts: null,
        contactId: this.props.contactId,
        showInsertForm: false,
    };

    async componentDidMount() {
        await this.fetchContacts();
        this.countryOptions = await CoreApi.fetchCountryOptions();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.contactId !== this.props.contactId) {
            this.setState({
                contactId: nextProps.contactId
            });
        }
    }

    fetchContacts = async () => {
        const response = await RafalaApi.Contacts();
        console.log(response);

        const contacts = (response && response.data) ? response.data.data : null;

        this.setState({
            contacts: contacts
        });
    }

    formatAddress(c) {
        let a = c.address1;
        if(c.address2 && c.address2.length > 0) a += ", " + c.address2;
        a += " - " + c.post_code;
        a += " - " + c.city;
        return a;
    }
    
    delete = async (id) => {
        this.props.modal.hide();
        this.props.overlay.setBusy();
        await RafalaApi.DeleteContact(id);
        await this.fetchContacts();
        this.props.overlay.setBusy(false);

        if(this.props.onDeleted)
            this.props.onDeleted(id);
    }

    onDelete = async (id) => {
        this.props.modal.confirm(AppContext.r["confirm-delete-address-heading"], AppContext.r["confirm-delete-address"], () => this.delete(id));
    }

    onInsertNewAddress = () => {
        this.setState({
            showInsertForm: true
        });

        if(this.props.onInsertNewAddress)
            this.props.onInsertNewAddress();
    }

    onClose = () => {
        this.setState({
            showInsertForm: false
        });

        if(this.props.onNewAddressCancelled)
            this.props.onNewAddressCancelled();
    }

    storeContact = async (fields) => {
        this.props.overlay.setBusy();

        const response = await RafalaApi.StoreContact(fields);
        console.log(fields, response) // TODEL

        await this.fetchContacts();
        
        const { contacts } = this.state;
        this.props.overlay.setBusy(false);

        const contactId = (contacts && contacts.length > 0) ? contacts[0].id : this.state.contactId;

        this.setState({
             showInsertForm: false,
             contactId: contactId
        });
        
        if(this.props.onNewAddressInserted)
            this.props.onNewAddressInserted(contactId, response.data.data);
    }

    handleAddressChange = (event) => {
        this.setState({
          contactId: parseInt(event.target.value)
        });

        const contact = this.state.contacts.find(x => x.id === parseInt(event.target.value));
        console.log(event.target.value, contact)

        if(this.props.onSelectAddress)
            this.props.onSelectAddress(parseInt(event.target.value), contact);
    }

    render() {
        if(!this.state.contacts)
            return AppContext.r["preloader"];

        const validationSchema = Yup.object().shape({
            alias: Yup.string().required(AppContext.r["required-field"]),
            name: Yup.string().required(AppContext.r["required-field"]),
            address1: Yup.string().required(AppContext.r["required-field"]),
            city: Yup.string().required(AppContext.r["required-field"]),
            post_code: Yup.string().required(AppContext.r["required-field"]),
        });
        
        const {user} = this.props.auth;

        const insertForm = (
            <div className="address-form">
                {/* <Button size="sm" className="insert-new-address-button" variant="link" onClick={this.onClose}>
                    {AppContext.r['close']}
                </Button> */}

                <h2>{AppContext.r["insert-new-address"]}</h2>

                <Formik
                    initialValues={{
                        alias: AppContext.r["default-address"],
                        name: user.name + (user.surname ? (" " + user.surname) : ""),
                        address1: "",
                        address2: "",
                        post_code: "",
                        city: "",
                        country_id: 228,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        this.storeContact(fields);
                    }}
                    render={({ errors, status, touched, initialValues }) => (
                        <FormikForm>
                            <div className="form-group">
                                <label htmlFor="alias">{AppContext.r["address-alias"]}</label>
                                <Field name="alias" type="text" className={'form-control' + (errors.alias && touched.alias ? ' is-invalid' : '')} />
                                <ErrorMessage name="alias" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="name">{AppContext.r["name"]}</label>
                                <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="address1">{AppContext.r["address"]}</label>
                                <Field name="address1" type="text" className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address1" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <Field name="address2" type="text" className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid' : '')} />
                                <ErrorMessage name="address2" component="div" className="invalid-feedback" />
                            </div>

                            <Row>
                                <Form.Group as={Col} md="3">
                                    <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                    <Field name="post_code" type="text" placeholder={AppContext.r["post-code"]} className={'form-control' + (errors.post_code && touched.post_code ? ' is-invalid' : '')} />
                                    <ErrorMessage name="post_code" component="div" className="invalid-feedback" />
                                </Form.Group>

                                <Form.Group as={Col} md="5">
                                    <Form.Label>{AppContext.r["city"]}</Form.Label>
                                    <Field name="city" type="text" placeholder={AppContext.r["city"]} className={'form-control' + (errors.city && touched.city ? ' is-invalid' : '')} />
                                    <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                </Form.Group>
                                
                                <Form.Group as={Col} md="4">
                                    <Form.Label>{AppContext.r["country"]}</Form.Label>

                                    <RemoteSelect name="country_id" value={228}
                                                options={this.countryOptions} />
                                </Form.Group>
                            </Row>

                            <div className="form-group">
                                <Button variant="link" onClick={this.onClose}>{AppContext.r["cancel"]}</Button>
                                <button type="submit" className="btn btn-primary mr-2">{AppContext.r["save"]}</button>
                            </div>

                            <div className="overlay" style={{display: this.state.showFormOverlay ? "block" : "none"}}>
                                {AppContext.r["preloader"]}
                            </div>
                        </FormikForm>
                    )} />
            </div>);

        // <input type="radio" value={c.id} checked={this.state.contactId === c.id} onChange={this.handleAddressChange} /> 
                        {/* <h3>{c.alias}</h3> */}

        const contactsMap = this.state.contacts.length === 0 ?
            <p className="text-center">{AppContext.r["no-addresses"]}</p> :
                this.state.contacts.map(c => 
                    <div key={c.id}>
                        {this.props.showSelect && 
                            <label className="radio-container">{c.alias}
                                <input type="radio" value={c.id} checked={this.state.contactId === c.id} onChange={this.handleAddressChange} />
                                <span className="checkmark"></span>
                            </label>}
                        <Button className="delete-button" variant="link" onClick={() => this.onDelete(c.id)}>
                            <i className="fas fa-trash-alt"></i> {AppContext.r["delete"]}
                        </Button>
                        <p>{this.formatAddress(c)}</p>
                    </div>);

        return (
            <>
                <div className="contacts">
                    {contactsMap}
                </div>

                {!this.state.showInsertForm && 
                    <Button size="sm" className="insert-new-address-button" variant="link" onClick={this.onInsertNewAddress}>
                        <i className="fas fa-plus-circle"></i> {AppContext.r['insert-new-address']}
                    </Button>}

                {this.state.showInsertForm && insertForm}
            </>);
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
};

export default connect(mapStateToProps, null)(withModal(withOverlay(Contacts)));