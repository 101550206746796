import React from "react";
import { Link } from "react-router-dom";

import AppContext from "app/AppContext";

export default function () {
  return (
    <div className="site-footer">
        <div className="legal">
            <Link to="/terms">
                {AppContext.r["terms"]}
            </Link>&nbsp;|&nbsp;
            <Link to="/privacy">
                {AppContext.r["privacy-policy"]}
            </Link>
        </div>

        <div className="signature">
            <a target="_blank" href={AppContext.s["provider"].websiteUrl}>
                <img src={AppContext.s["provider"].logoPath} alt="logo" width="80px" />
            </a>
        </div>
    </div>
  );
}
