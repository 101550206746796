import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import ProjectIdsEnum from 'settings/ProjectIdsEnum';
import RafalaApi from 'api/Rafala';
import { withModal } from 'framework/Modal';
import { format } from 'date-fns';
import PaymentInstructions from 'components/PaymentInstructions';

class Orders extends React.Component {
    state = {
        orders: null,
        orderStatuses: null,
        shopId: null,
    }

    constructor(props) {
        super(props);
        
        if(this.props.updateLayout)
            this.props.updateLayout();

        //if(this.props.shopId)
        //    this.state.shopId = this.props.shopId;
        this.state.shopId = AppContext.s["project-id"];
    }

    async componentDidMount() {
        // // Retrieve shopId
        // const response = await FoodBond.RetrieveShopBySubDomain();
        // console.log(response);

        // if(response) {
        //     this.setState({
        //         shop: response.data.data,
        //         shopId:  response.data.data.id
        //     })
        // }
        
        await this.fetchOrders();
    }

    fetchOrders = async () => {
        const response = await RafalaApi.Orders(this.state.shopId);
        const orderStatuses = await RafalaApi.OrderStatuses();
        // console.log(response);

        this.setState({
            orders: (response && response.data) ? response.data.data : null,
            orderStatuses: orderStatuses.data
        });
    }

    onUpdate = async () => {
        this.setState({
            orders: null
        });

        await this.fetchOrders();
    }

    orderStatusById(statusId) {
        return this.state.orderStatuses[statusId - 1];
    }

    async cancelOrder(id) {
        // const r = this.state.shop;
        this.props.modal.setBusy(true);
        const response = await RafalaApi.CancelOrder(id);
        this.props.modal.setBusy(false);
        console.log(response);

        if(response.status === 200) {
            this.props.modal.hide();
        } else {
            //<p><i className="fas fa-phone"></i> {r.phone} | <a className="email-icon" target="_blank" rel="noopener noreferrer" href={"mailto:"+r.email}><i className="far fa-envelope"></i> {r.email}</a></p>
            
            const modalMessage = (
                <>
                    <i className="far fa-frown icon"></i>
                    <p className="message">{response.status === 423 ? AppContext.r["cannot-delete-order"] : AppContext.r["cannot-delete-order"]}</p>
                    <Button onClick={() => { this.props.modal.hide(); this.props.modal.setFree(); }}>{AppContext.r['close']}</Button>
                </>);
                
            this.props.modal.setMessage(modalMessage, false);
        }

        this.onUpdate();
    }
    
    onCancel = async (id) => {
        // Ask for confirmation
        this.props.modal.confirm(AppContext.r["confirm-delete-order-heading"], AppContext.r["confirm-delete-order"],
            () => this.cancelOrder(id));
    }
    
    render() {
        let view = <p className="text-center">{AppContext.r["must-be-logged"]}</p>;

        if(this.props.auth.user) {
            const { orders } = this.state;
            const ordersMap = [];

            if(orders && this.state.orderStatuses !== null) {
                for(let order of orders)
                    ordersMap.push (
                        <div className="order" key={order.id}>
                            <Row>
                                <Col md={4}>
                                    <div className="label">{AppContext.r['order']} REF.</div>
                                    <div>{(AppContext.s["project-id"] === ProjectIdsEnum.Enoteca ? "E" : "R") + order.id}</div>
                                </Col>

                                <Col>
                                    <div className="label">{AppContext.r['placed-at']}</div>
                                    <div className="created-at">{format(new Date(order.created_at), "d MMM yyyy - H:mm:ss")}</div>
                                </Col>
                            </Row>

                            {order.requested_date &&
                                <>
                                    <div className="label">{AppContext.r['requested-date']}</div>
                                    <div className="created-at">{order.requested_date}</div>
                                </>}
                            
                            <div className="items">
                                {order.items.map(item => (
                                    <div className="item">
                                        {item.pivot.quantity + " x "} {item.name}
                                    </div>
                                ))}
                            </div>
                            <p>Delivery Fee: {AppContext.s["currency"]} {order.delivery_fee.toFixed(2)}</p>
                            <p>TOTAL: {AppContext.s["currency"]} <b>{order.price + order.delivery_fee}</b></p>
                            
                            <div className="note">{order.note}</div>
                            
                            { order.shop_order_status_id > 1 ? "" : 
                                <Button className="cancel-order-button" variant="link" onClick={() => this.onCancel(order.id)}>{AppContext.r['cancel']}</Button> }

                            <div className="status-container">
                                <div className={"status index-" + order.shop_order_status_id}>{AppContext.r["status"]}: {this.orderStatusById(order.shop_order_status_id).title}</div>
                            </div>
                            
                            {order.shop_order_status_id === 1 &&
                                <PaymentInstructions paymentTypeId={order.payment_type_id} orderTypeId={order.shop_order_type_id} /> }

                            <hr />
                        </div>
                    );
            }

            view = (
                <>
                    <Button variant="link" className="update-orders-button" onClick={this.onUpdate}>
                        {AppContext.r['update']}
                    </Button>

                    {(!this.state.shopId || !orders) ? AppContext.r['preloader'] : 
                        ordersMap.length > 0 ? ordersMap : <p className="text-center">{AppContext.r['no-orders']}</p> }
                </>);
        }

        return (
            <Container className="orders page">
                <h2>{AppContext.r['orders']}</h2>
                
                {view}
            </Container>);
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(withModal(Orders));