import React, { Component } from 'react';
import { connect } from 'react-redux';
import Counter from "components/Counter";

// import { updateCartQuantity, removeFromCart } from '../../store/actions/cartActions';
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import {priceTag} from "./Cart";

import AppContext from '../../app/AppContext';
import ProjectIdsEnum from '../../settings/ProjectIdsEnum';

class Item extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
            quantity: this.props.item.quantity,
            btnVisible: false
        };
    }
 
    handleChange = (e) => {
        if(e.target.value <= 0) {
            alert("Quantity must be greater than or equal to 1");
 
            return;
        }
 
        if(e.target.value > this.props.item.product.amount) {
            alert("You have exceeded the available items of this product!");
            return;
        }
 
        if(this.state.quantity !== e.target.value) {
            this.setState({
                quantity: e.target.value,
                btnVisible: true
            });
        }
    }
 
    handleSubmit = (e) => {
        e.preventDefault();
 
        // this.props.updateCartQuantity(this.props.item.id, this.state.quantity);
 
        this.setState({
            btnVisible: false
        });
    }
 
    handleRemove = (e) => {
        console.log(this.props.item);
        if(this.props.item.quantity > 1)
            this.props.updateCartQuantity(this.props.item.cartItemId, this.props.item.quantity -1);
        else
            this.props.removeFromCart(this.props.item.cartItemId);
    }
 
    handleAdd = (e) => {
        console.log(this.props.item);
        this.props.updateCartQuantity(this.props.item.cartItemId, this.props.item.quantity + 1);
    }
 
    render() {
        const { item } = this.props;

        let extras = "";

        for(var i = 0; i < item.extras.length; i++) {
            extras += item.extras[i].name;
            if((i+1) < (item.extras.length))
                extras += ", ";
        }

        const { product } = item;

        return (
            <div className="cart-item">
                <div className="product">
                    <div className="image-container"
                        style={{ backgroundImage: "url(" + AppContext.s["api-url"] + "/shop/items/" + product.id + "/thumb)" }}>
                    </div>

                    <div className="info">
                        {/* <span>REF: <b>{(AppContext.s["project-id"] === 2 ? "E" : "R") + product.id}</b> - Pack of { product.package_quantity + " x " + product.package_size }</span> */}
                        <p>{item.quantity} x { product.name } (<span>REF: <b>{(AppContext.s["project-id"] === 2 ? "E" : "R") + product.id}</b> - Pack of { product.package_quantity + " x " + product.package_size }</span>)</p>

                        {/* <p><strong>{product.shop_item_availability_state.name}</strong></p> */}
                        {product.shop_item_availability_state &&
                            <p><strong>{product.shop_item_availability_state.name}</strong></p> }

                        {(product.shop_item_availability_state && product.shop_item_availability_state.id === 3) &&
                            <>
                                <p className="small">You have selected a <strong>pre-order only</strong> item.</p>
                                <p className="small">We will be in touch with details of a delivery date and all your products will arrive at the same time.</p>
                            </> }
                            
                        {product.price > 0 && (product.shop_item_category.is_retail || AppContext.s['project-id'] == ProjectIdsEnum.Enoteca) ?
                            priceTag(item) : "Request a quote"}
                    </div>

                    <div className="options">
                        {/* <button type="button" onClick={this.handleRemove} className="btn btn-link btn-xs remove-button">
                            <i className="fas fa-trash-alt"></i> {AppContext.r["remove"]}
                        </button> */}
{/* 
                        <div className="counter">
                            <Button variant="link" onClick={this.handleRemove}><i className="fas fa-minus-circle"></i></Button>
                        </div> */}

                        <Counter value={this.props.item.quantity} 
                            onIncrease={this.handleAdd} onDecrease={this.handleRemove} />
                    </div>
                </div>

                <div className="extras">
                    {extras}
                </div>

                <div className="note">
                    {item.note}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};
 
const mapDispatchToProps = (dispatch) => {
    return {
        updateCartQuantity: (cartItemId, quantity) => 
            { dispatch(cart.actions.updateCartQuantity(cartItemId, quantity)) },
        removeFromCart: (cartItemId) => 
            dispatch(cart.actions.removeFromCart(cartItemId))
    }
};
 
export default connect(mapStateToProps, mapDispatchToProps)(Item);