import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import HelpIcon from '@material-ui/icons/Help';

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import { toggleDrawer } from "components/Header/Header";
import styles from "components/Header/headerLinksStyle.js";

import AppContext from "./AppContext";
import ProjectIdsEnum from "settings/ProjectIdsEnum";
import { withNavigation } from './withNavigation';

const useStyles = makeStyles(styles);

function HeaderLinks(props) {
    const classes = useStyles();

    const onNavigate = () => {
        toggleDrawer(false);
    }
    
    return (
        <div className={"drawer " + classes.collapse}>
            <List className={"header-links " + classes.list + " " + classes.mlAuto}>
                {/* <ListItem className={classes.listItem}>
                    <Button href="/#contact" className={classes.navLink} color="transparent"
                        onClick={e => { props.smoothScroll(e, "contact"); onNavigate(); }}>
                        {AppContext.r["contact-us"]}
                    </Button>
                </ListItem> */}

                {/* {(AppContext.s['project-id'] === ProjectIdsEnum.RafalaUk) &&
                    <ListItem className={classes.listItem}>
                        <Button href="https://www.enotecawineshop.co.uk" className={"header-link " + classes.navLink} color="transparent">
                           Shop Wines
                        </Button>
                    </ListItem> } */}
                
                {(AppContext.s['project-id'] === ProjectIdsEnum.RafalaUk) && AppContext.Subdomain !== "trade" &&
                    <ListItem className={classes.listItem}>
                        <Button href="https://trade.rafalauk.com" className={"header-link " + classes.navLink} color="transparent">
                            Trade Account
                        </Button>
                    </ListItem> }
                
                {(AppContext.s['project-id'] === ProjectIdsEnum.RafalaUk) && AppContext.Subdomain === "trade" &&
                    <ListItem className={classes.listItem}>
                        <Button href="https://rafalauk.com" className={"header-link " + classes.navLink} color="transparent">
                            Retail
                        </Button>
                    </ListItem> }

                <ListItem className={classes.listItem}>
                    <Button className={classes.navLink} color="transparent" onClick={onNavigate}>
                        <Link to="/contact" className="header-link">
                            {AppContext.r["contact-us"]}
                        </Link>
                    </Button>
                </ListItem>

                <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding navDropdown hoverColor={"secondary"} buttonText="Help"
                        buttonProps={{
                            className: "header-link " + classes.navLink,
                            color: "transparent"
                        }}
                        dropdownList={[
                            <Link to="/help" className={classes.dropdownLink} onClick={onNavigate}>
                                <HelpIcon className={classes.dropdownIcons} /> Payments, Deliveries & Returns
                            </Link>,
                            <Link to="/terms" className={classes.dropdownLink} onClick={onNavigate}>
                                <DescriptionRoundedIcon className={classes.dropdownIcons} /> {AppContext.r["terms"]}
                            </Link>,
                            <Link to="/privacy" className={classes.dropdownLink} onClick={onNavigate}>
                                <DescriptionRoundedIcon className={classes.dropdownIcons} /> {AppContext.r["privacy-policy"]}
                            </Link>,
                        ]} />
                </ListItem>
            </List>

            <List className={"social-list " + classes.list + " " + classes.mlAuto}>
                {AppContext.s["facebook-page-url"] && 
                    <ListItem className={"social-list-item " + classes.listItem + " " + classes.listItemInline}>
                        <Button color="transparent" target="_blank"
                            href={AppContext.s["facebook-page-url"]}
                            className={classes.navLink + " " + classes.navLinkJustIcon}>
                            <i className={"fab fa-facebook"} />
                        </Button>
                    </ListItem>}

                {AppContext.s["twitter-page-url"] && 
                    <ListItem className={"social-list-item " + classes.listItem + " " + classes.listItemInline}>
                        <Button color="transparent" target="_blank"
                            href={AppContext.s["twitter-page-url"]}
                            className={classes.navLink + " " + classes.navLinkJustIcon}>
                            <i className={"fab fa-twitter"} />
                        </Button>
                    </ListItem>}

                {AppContext.s["instagram-page-url"] && 
                    <ListItem className={"social-list-item " + classes.listItem + " " + classes.listItemInline}>
                        <Button color="transparent" target="_blank"
                            href={AppContext.s["instagram-page-url"]}
                            className={classes.navLink + " " + classes.navLinkJustIcon}>
                            <i className={"fab fa-instagram"} />
                        </Button>
                    </ListItem>}
            </List>
        </div>
    );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withNavigation(HeaderLinks);