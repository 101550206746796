import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";

// import {BuilderPage} from "./pages/BuilderPage";
import Home from "app/views/Home";
import MyAccount from './modules/Auth/pages/MyAccount';
import ResetPassword from 'app/modules/Auth/pages/ResetPassword';
import PrivacyPolicy from 'app/views/PrivacyPolicy';
import Terms from 'app/views/Terms';
import Orders from './views/Orders';
import Help from './views/Help';
import Contact from './views/Contact';
import Layout from "./Layout";
// import {DashboardPage} from "./pages/DashboardPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <ContentRoute exact path="/" render={() => <Layout><Home /></Layout>} />

                <ContentRoute path="/myaccount" render={() => <Layout><MyAccount /></Layout>} />
                <ContentRoute path="/terms" render={() => <Layout><Terms /></Layout>} />
                <ContentRoute path="/privacy" render={() => <Layout><PrivacyPolicy /></Layout>} />
                <ContentRoute path="/orders" render={() => <Layout><Orders /></Layout>} />
                <ContentRoute path="/help" render={() => <Layout><Help /></Layout>} />
                <ContentRoute path="/contact" render={() => <Layout><Contact /></Layout>} />
                
                <ContentRoute path="/resetpassword">
                    <Layout>
                        <ResetPassword />
                    </Layout>
                </ContentRoute>
                
                <ContentRoute exact path='/:category' render={({ match }) => <Layout><Home category={match.params.category} /></Layout> } />

                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
