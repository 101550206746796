import React from 'react';
import ReactDOM from 'react-dom';
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './assets/scss/main.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import "react-datepicker/dist/react-datepicker.css";
import {
    MetronicSplashScreenProvider,
  } from "./_metronic/layout";

_redux.setupAxios(axios, store);
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <React.StrictMode>
    <MetronicSplashScreenProvider>
        <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </MetronicSplashScreenProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();