// Toggle Button v1.0

import React, {Component} from 'react';
import { Button } from 'react-bootstrap';

export default class ToggleButton extends Component {
    constructor (props) {
        super(props);

        this.state = {
            toggled: (this.props.toggled === true || this.props.toggled === 1) ? true : false
        }

        this.on =  (this.props.on) ? this.props.on : "on";
        this.off = (this.props.off) ? this.props.off : "off";
    }

    onClick = () => {
        if(this.props.onToggle)
            this.props.onToggle(!this.state.toggled);

        this.setState({
            toggled: !this.state.toggled
        })
    }

    render() {
        const baseClass = (this.props.className) ? "toggle-button " + this.props.className : "toggle-button";

        return <Button size="sm" variant="outline-info" className={this.state.toggled ? baseClass + " toggled" : baseClass}
                        disabled={this.props.disabled}
                        onClick={this.onClick}>{this.state.toggled ? this.on : this.off}</Button>
    }
}