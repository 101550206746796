import Axios from 'axios';

import AppContext from '../app/AppContext';

export default class Core {
    static errorLog(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        return (error.response) ? error.response : null;
    }
    
    static async fetchAsync (url, fieldToMap) {
        const res = await Axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        if(fieldToMap !== undefined && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }

        return data;
    };

    static async fetchCountryOptions() {
        return await this.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
    }
    
    static async fetchItem(url, callback, cancelToken = null) {
        console.log("Fetch " + url)
        const config = {
            // cancelToken: this.CancelTokenSource.token,
            //headers: { Authorization: AppContext.Auth.bearer }
        };

        if(cancelToken)
            config.cancelToken = cancelToken;

        Axios.get(url, config)
            .then(response => {
                if(callback)
                    callback(response.data.data);
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('error ' + error);
                }
            });
    }

    static async updateItem(url, values) {
        console.log("UPDATE", values, AppContext.Auth.bearer);
        // var config = { headers: {
        //     Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjVlMTkzYzE5M2MyMzJlNWRkZTU0Zjk2NzNlNTczYWI3MTI3YzRmYjA1ZDk1NDQ4MzRmYTlhM2UzNjQxNDk4NGQ2MGZlNzJiMDJkYWNhOGMyIn0.eyJhdWQiOiIyIiwianRpIjoiNWUxOTNjMTkzYzIzMmU1ZGRlNTRmOTY3M2U1NzNhYjcxMjdjNGZiMDVkOTU0NDgzNGZhOWEzZTM2NDE0OTg0ZDYwZmU3MmIwMmRhY2E4YzIiLCJpYXQiOjE2MDE5MDEzNTksIm5iZiI6MTYwMTkwMTM1OSwiZXhwIjoxNjMzNDM3MzU4LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.FnAOFExfaE7treW8ypcR7qFGsHyCV4xDvWkp4O8k7LqE3H2zZ94vXlAbW_gJbgBJNw78DUxtxbkNbPeCsfwTbtRqmRp4YY06_u6s-j7fu9LGAWaTh2YUwtVcxox8Rc7RCtTobmHFNsf6GeHWJXcrG1kS8tBGecK9-0wM5X83gtAeT0LlYvaJyAdIGm4C7_p1PM8f_aeuGlFqUzQ17Pogly-w8e5xPx7gJTclCqh7NlcabGyPwRYh8LrUXT9MWCJicFW2NetFekqm-6_FSRGA6g1_FUzboy2ikq9qwBgRfyYXdLDbFb_zqC7Afr0FFsdAw5L5lfMsU4EFzcmzJI4DzKX7vY4gfjXflp3jI6izqc3yWKOPip_viP-hYNo_Z2X3fgxvzpoa7Q4-B2r_HvQzV7CwFEoZbaWV-szUKEB-OQBn0ETJygfmmDbfIlfeyumC8Zou7O1NqkMSp-16GchdTXVF8Qmrn21Pica-f-gEN1hq-he88ezHtwcBBdhLDppR81WVi5sWYA7ISsfn-6YXjZLJ09erWwbFShCi-v1sKVXOtYPr8hWGqYrwzndbsH-bJPrp0JjgdmM7zTdEc2_uL2YY1WUnlt0FeKk6OS2Dt1I3sOcOZlvD28uVc_f-kSKU0iPO1jIzHP1BQ-8iqIEmHcgINgw0AKjMPy5N7H2JI5g",
        //      } };

        return await Axios.put(url, values)
                    .catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            console.log(error.response.status);
                            return error.response;
                        }
                    });
    }

    static async deleteItem(url) {
        console.log("DELETE", url);
        //var config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/json' }, crossdomain: true };

        await Axios.delete(url)
                  .catch(function (error) {
                      if (error.response) {
                          console.log(error.response.status);
                          return error.response;
                      }
                  });
    }

    static async uploadFile(url, formData) {
        const config = {
            headers: {
            // Authorization: Auth.bearer,
            'Accept': '*/*',
            //'Content-Length': file.size,
            'Content-Type': "multipart/form-data"
            }
        };
            
        return await Axios.post(url, formData, config)
            .catch((error) => {
                console.log(error);
                console.log(error.response);

                return error.response;
            });
    }

    static async Contacts() {
        var config = { headers: { 'Content-Type': 'application/json' } };
        
        return await Axios.get(AppContext.s["api-url"] + "/contacts", config)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async EditUser(id, user) {
        var config = { headers: { 'Content-Type': 'application/json' } };
        console.log("UPDATE " + AppContext.s["api-url"] + "/users/" + id, user);
        
        return await Axios.put(AppContext.s["api-url"] + "/users/" + id, user, config)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async uploadImageFile(file, mediaTransferApiPath, attributeName = 'image') {
        console.log(file);
        const config = {
            headers: {
                // Authorization: AppContext.Auth.bearer,
                'Accept': '*/*',
                //'Content-Length': file.size,
                'Content-Type': "multipart/form-data"
            }
        }

        const formData = new FormData();
        formData.append('file', file);

        return await Axios.post(mediaTransferApiPath, formData, config)
            .catch((error) => {
                console.log(error);
                console.log(error.response);
                // TODO: Show error
            })
    }
}