import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import StripeCheckout from "framework/StripeCheckout";

import AppContext from '../../app/AppContext';
import RafalaApi from 'api/Rafala';
import Item from './Item';
import withOverlay from '../../framework/withOverlay';
import Contacts from '../Contacts';
import ProjectIdsEnum from '../../settings/ProjectIdsEnum';
// import { now } from 'lodash';

export class OrderTypesEnum {
    static get order() { return 1; }
    static get delivery() { return 2; }
    static get collection() { return 3; }
    static get quoteRequest() { return 4; }
}

export class PaymentTypesEnum {
    static get card() { return 1; }
    static get bankTransfer() { return 2; }
    static get paypal() { return 3; }
    static get cashOnDelivery() { return 4; }
    static get cardOverThePhone() { return 5; }
    static get cashOnCollection() { return 6; }
}

class Cart extends Component {
    state = {
        note: "",
        contacts: null,
        contactId: null,
        contact: null,
        paymentType: null,
        showCheckout: true,
        collectionShopId: AppContext.s["project-id"],
        orderType: -1,
        total: 0,
        store: null,
        step: 0, // Options/Payment View
        disableMinOrder: false
    };

    get orderTypesCount() {
        return (this.props.supportOrderToTable ? 1 : 0) + (this.props.supportTakeAway ? 1 : 0) + (this.props.supportDelivery ? 1 : 0) + (this.props.supportQuoteRequest ? 1 : 0);
    }

    checkIfMinOrder = async () => {
        // const response = await RafalaApi.MyLastOrder();

        // if (response && response.status === 200) {
        //     const dt = Date.parse(response.data.data.created_at);
        //     const timespan = (new Date() - dt);

        //     // MAYDO: last order should be 24-hour allowed from the server

        //     if ((timespan / 1000) < (24 * 60 * 60))
        //         this.setState({
        //             disableMinOrder: true
        //         });
        // }
    }

    componentWillReceiveProps(nextProps) {
        const postCode = (this.state.contact) ? this.state.contact.post_code : "";

        if (nextProps.cart !== this.props.value) {
            this.setState({
                total: cartCheckoutTotal(nextProps.cart),
                deliveryFee: this.calcDeliveryFee(postCode)
            });
        }
    }

    async componentDidMount() {
        const { user } = this.props.auth;

        if (!this.state.store) {
            const response = await RafalaApi.Shop(AppContext.s['project-id']);

            if (response && response.data)
                this.setState({
                    store: response.data.data
                })
        }

        if (user) {
            await this.checkIfMinOrder();
        }

        const { store } = this.state;

        const key = store.stripe_test === 1 ? AppContext.s["test-stripe-key"] : AppContext.s["stripe-key"];

        this.setState({
            promise: loadStripe(key, {
                stripeAccount: store.stripe_id
            })
        });

        // If only one order type is enabled, automatically selects it
        if (this.orderTypesCount === 1) {
            let selectedOrderType = -1;

            if (this.props.supportOrderToTable)
                selectedOrderType = OrderTypesEnum.order;
            if (this.props.supportDelivery)
                selectedOrderType = OrderTypesEnum.delivery;
            if (this.props.supportTakeAway)
                selectedOrderType = OrderTypesEnum.collection;

            this.setState({
                orderType: selectedOrderType
            });
        }

        // Rafalà: support only quote request if there are no priced items
        const { cart } = this.props;
        const total = cartCheckoutTotal(cart);

        this.setState({
            total: total
        });

        if (total === 0)
            this.setState({
                orderType: OrderTypesEnum.quoteRequest
            });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    addToCart = (product) => {
        this.props.addToCart(product);
    }

    checkout = async () => {
        const { cart } = this.props;
        const { orderType, contactId, paymentType } = this.state;

        if (orderType <= 0) {
            this.props.overlay.setView(AppContext.r["specify-order-type"], true, true);
            return;
        }

        if (orderType === OrderTypesEnum.delivery && !contactId) {
            this.props.overlay.setView(AppContext.r["no-address-selected"], true, true);
            return;
        }

        if ((orderType === OrderTypesEnum.delivery || orderType === OrderTypesEnum.collection) && !paymentType) {
            this.props.overlay.setView(AppContext.r["no-payment-type-selected"], true, true);
            return;
        }

        const postCode = (this.state.contact) ? this.state.contact.post_code : "";
        const deliveryFee = this.calcDeliveryFee(postCode);

        // This code has been moved at the bottom of the file
        // const total = cartCheckoutTotal(cart);

        // if(total > 0 && total < AppContext.s["min-order"]) {
        //     this.props.overlay.setView(AppContext.r["min-order-is"] + AppContext.s["currency"] + AppContext.s["min-order"], true, true);
        //     return;
        // }

        if (this.props.checkout) {
            //const convert = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2);
            //const requestedDateTime = format(this.state.requestedDate,'yyyy-MM-dd') + " " + convert(this.state.requestedTime);
            const requestedDateTime = null;

            this.props.checkout(cart, this.state.note, this.state.contactId, this.state.orderType, this.state.selectedTable, requestedDateTime, this.state.paymentType, deliveryFee, this.state.collectionShopId);
        }

        this.setState({
            step: 2,
            deliveryFee: deliveryFee
        });
    }

    onInsertNewAddress = () => {
        this.setState({
            showCheckout: false,
        });
    }

    onNewAddressInsertedOrCancelled = (contactId, contact) => {
        const selectedContact = contact ? contact : this.state.contact;

        this.setState({
            showCheckout: true,
            contactId: contactId ? contactId : this.state.contactId,
            contact: selectedContact,
            deliveryFee: this.calcDeliveryFee(selectedContact.post_code)
        });
    }

    handlePurchaseMethodChange = (event) => {
        this.setState({
            orderType: parseInt(event.target.value)
        });
    }

    handleCollectionPointChange = (event) => {
        this.setState({
            collectionShopId: parseInt(event.target.value)
        });
    }

    handlePaymentMethodChange = (event) => {
        this.setState({
            paymentType: parseInt(event.target.value)
        });
    }

    calcDeliveryFee = (postCode = null) => {
        const { cart } = this.props;

        const total = cartCheckoutTotal(cart);

        if (total >= AppContext.s['min-order-free-delivery'])
            return 0;

        const { orderType } = this.state;

        if (!postCode && this.state.contact)
            postCode = this.state.contact.post_code;

        if (orderType !== OrderTypesEnum.delivery)
            return 0;

        const trimmedPostCode = postCode.replace(/ /g, '').toUpperCase();

        const outcode = postCode.substring(0, trimmedPostCode.length - 3);
        // const incode = postCode.substring(postCode.length - 3, postCode.length);

        const fees = [
            {
                label: "Liverpool",
                codes: ["L1", "L2", "L3", "L8", "L15", "L17", "L18", "L20", "L21", "L22", "L23", "L29", "L30", "L37", "L38", "L39", "L40"],
                fee: 0
            },
            {
                label: "Wirral",
                codes: ["CH41", "CH42", "CH43", "CH44", "CH45", "CH60"],
                fee: 10
            },
            {
                label: "Preston/Southport",
                codes: ["PR1", "PR2", "PR8", "PR9"],
                fee: 5
            }
        ];

        for (const f of fees) {
            if (f.codes.find(c => c === outcode))
                return f.fee;
        }

        // EDITED: Previous post codes - 09/08/2023
        // const lPostCodes = [ 1, 2, 3, 8, 15, 17, 18, 20, 21, 22, 23, 29, 30, 37, 38, 39, 40 ];
        // if(postCode.startsWith("L") && 
        //     (lPostCodes.find(x => x === parseInt(postCode.substring(1,3)))
        //         || (postCode.length === 5 && lPostCodes.find(x => x === parseInt(postCode.substring(1,2)))))) return 0;

        // const prPostCodes = [ 8, 9, 1, 2 ];
        // if(postCode.startsWith("PR") && prPostCodes.find(x => x === parseInt(postCode.substring(2,3)))) return 5;

        // EDITED: Previous post codes - 28/02/2023

        // if(postCode === "" || (postCode.startsWith("L") && !isNaN(postCode.substring(1,2)))) return 0;

        // const p24 = parseInt(postCode.substring(2,4));
        // const chPostCodes = [41, 42, 43, 44, 45, 46, 47, 48, 60, 61, 61, 62, 63];

        // if(postCode.startsWith("PR") || (postCode.startsWith("CH") && chPostCodes.find(x => x === p24))) return 5;

        return AppContext.s["max-delivery-cost"];
    }

    render() {
        const { user } = this.props.auth;
        const { store, orderType, paymentType, collectionShopId, promise, step, disableMinOrder, total, deliveryFee } = this.state;

        // const postCode = (this.state.contact) ? this.state.contact.post_code : "";
        //const deliveryFee = this.calcDeliveryFee(postCode);
        // const total = cartCheckoutTotal(this.props.cart, deliveryFee);

        const cart = this.props.cart.length > 0 ? (
            <div className="cart-panel">
                <div className="body">
                    {
                        this.props.cart.map(item => {
                            return (
                                <div key={item.cartItemId}>
                                    <Item item={item} />
                                    <hr />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        ) : (
            <div className="cart-panel">
                <div className="body">
                    <p>{AppContext.r["empty-cart"]}</p>
                </div>
            </div>
        );

        const acceptConds = (
            <>
                <p className="text-center">Do you know about our delivery fees, schedule, payment methods and returns?</p>
                <p className="text-center">Check out our page&nbsp;
                    <Link to="/help" onClick={this.props.modal.hide}>Payments, Deliveries & Returns</Link> for details and tick the following box to confirm you understand.</p>
                <FormControlLabel label="I agree" className="agree-button"
                    control={
                        <Checkbox
                            checked={this.state.agreed}
                            onChange={(event) => {
                                this.setState({ ...this.state, [event.target.name]: event.target.checked });
                            }}
                            name="agreed"
                            color="primary"
                        />
                    } />
            </>
        );

        const selectOrderType = (
            <div className="purchase-method-selector">
                {this.state.total > 0 && this.props.supportOrderToTable ?
                    <label className="radio-container">{AppContext.r['purchase-method-order']}
                        <input type="radio" value={OrderTypesEnum.order} onChange={this.handlePurchaseMethodChange}
                            checked={orderType === OrderTypesEnum.order} />
                        <span className="checkmark"></span>
                    </label> : ""}

                {this.state.total > 0 && this.props.supportDelivery ?
                    <label className="radio-container">{AppContext.r['purchase-method-delivery']}
                        <input type="radio" value={OrderTypesEnum.delivery} onChange={this.handlePurchaseMethodChange}
                            checked={orderType === OrderTypesEnum.delivery} />
                        <span className="checkmark"></span>
                    </label> : ""}

                {this.state.total > 0 && this.props.supportTakeAway ?
                    <label className="radio-container">{AppContext.r['purchase-method-collection']}
                        <input type="radio" value={OrderTypesEnum.collection} onChange={this.handlePurchaseMethodChange}
                            checked={orderType === OrderTypesEnum.collection} />
                        <span className="checkmark"></span>
                    </label> : ""}

                {this.state.total === 0 ?
                    <label className="radio-container">{AppContext.r['purchase-method-quote-request']}
                        <input type="radio" value={OrderTypesEnum.quoteRequest} onChange={this.handlePurchaseMethodChange}
                            checked={orderType === OrderTypesEnum.quoteRequest} />
                        <span className="checkmark"></span>
                    </label> : ""}
            </div>
        );

        const selectCollectionPoint = (
            <>
                <h2>Select a collection point</h2>

                <div className="collection-point-selector">
                    <label className="radio-container">
                        <b>Rafala UK</b>

                        <div className="address">
                            Unit 2, Clayton Business Centre, Langley Place, Burscough, Ormskirk L40 8JS<br />
                            Opening times: Monday – Friday 9am-4pm
                        </div>

                        <input type="radio" value={1} onChange={this.handleCollectionPointChange}
                            checked={collectionShopId === 1} />
                        <span className="checkmark"></span>
                    </label>

                    {/* <label className="radio-container">
                        <b>Enoteca Wine Shop</b>

                        <div className="address">
                            Enoteca, Huntley’s Farm Shop, Whalley Road, Samlesbury, Lancashire, PR5 0UN<br />
                            Opening times: Monday – Saturday 10am – 4.30pm, Sunday 11am – 3.45pm
                        </div>

                        <input type="radio" value={2} onChange={this.handleCollectionPointChange}
                            checked={collectionShopId === 2} />
                        <span className="checkmark"></span>
                    </label> */}
                </div>
            </>
        );

        const onAddressDeleted = (id) => {
            if (this.state.contactId === id)
                this.setState({
                    contact: null,
                    contactId: null,
                    deliveryFee: null
                });
        };

        const onSelectAddress = (contactId, contact) => {
            // Not allow payment type "cash on deliver" with a courier
            let paymentType2 = paymentType;
            const deliveryFee = this.calcDeliveryFee(contact.post_code);

            if ((orderType === OrderTypesEnum.delivery && deliveryFee >= 10
                && paymentType === PaymentTypesEnum.cashOnDelivery))
                paymentType2 = null;

            this.setState({
                contactId: contactId,
                contact: contact,
                paymentType: paymentType2,
                deliveryFee: deliveryFee
            });
        };

        const selectAddress = (
            <div className="shipping-addresses">
                <h2>{AppContext.r["deliver-to"]}</h2>

                <Contacts showSelect showTitle={false} ref={this.contactsRef}
                    contactId={this.state.contact ? this.state.contact.id : null}
                    onDeleted={onAddressDeleted}
                    onSelectAddress={onSelectAddress}
                    onInsertNewAddress={this.onInsertNewAddress}
                    onNewAddressInserted={this.onNewAddressInsertedOrCancelled}
                    onNewAddressCancelled={this.onNewAddressInsertedOrCancelled} />
            </div>
        );

        const stepperComponent = (
            <Stepper className="stepper" activeStep={step}>
                <Step key={"cart-review-step"}>
                    <StepLabel>Cart Review</StepLabel>
                </Step>

                <Step key={"pay-step"}>
                    <StepLabel>Pay</StepLabel>
                </Step>

                <Step key={"paid-step"} completed={false}>
                    <StepLabel>Order Placed</StepLabel>
                </Step>
            </Stepper>);

        const request = {
            cart: this.props.cart,
            shop_id: AppContext.s["project-id"],
            delivery_fee: deliveryFee
        };

        // const paymentView = promise ? (
        //     <>
        //         {stepperComponent}

        //         <Button className="backtocart-button"
        //             onClick={() => this.setState({ step: 0 })}><KeyboardArrowLeft /> Back to cart</Button>

        //         <div className="stripe">
        //             <Elements stripe={promise}>
        //                 <StripeCheckout testMode={store.stripe_test}
        //                     request={request}
        //                     user={user}
        //                     amount={AppContext.s['currency'] + " " + (total + (!this.state.contact ? 0 : this.calcDeliveryFee(this.state.contact.post_code))).toFixed(2)}
        //                     onSucceeded={this.checkout} />
        //             </Elements>
        //         </div>
        //     </>) : "";

        const optionsView = this.props.cart.length > 0 && (
            <div className="cart-options">
                <Form.Control type="text" name="note" placeholder="Note" maxLength={255}
                    value={this.state.note} onChange={e => this.handleChange(e)} />

                <br />

                {(total > 0) &&
                    <>
                        {acceptConds}

                        {selectOrderType}
                    </>}

                {(orderType === OrderTypesEnum.collection) &&
                    selectCollectionPoint}

                {(orderType === OrderTypesEnum.delivery) &&
                    selectAddress}

                {(orderType > 0 && total > 0) && (
                    <div className="payment-method">
                        <h2>{AppContext.r["payment-method"]}</h2>

                        <div className="payment-options">
                            <label className="radio-container">{AppContext.r["payment-types"][orderType][0]}
                                <input type="radio" value={PaymentTypesEnum.card} onChange={this.handlePaymentMethodChange}
                                    checked={paymentType === PaymentTypesEnum.card} />
                                <span className="checkmark"></span>
                            </label>

                            <label className="radio-container">{AppContext.r["payment-types"][orderType][2]}
                                <input type="radio" value={PaymentTypesEnum.paypal} onChange={this.handlePaymentMethodChange}
                                    checked={paymentType === PaymentTypesEnum.paypal} />
                                <span className="checkmark"></span>
                            </label>

                            {(orderType === OrderTypesEnum.delivery && deliveryFee < 10) &&
                                <label className="radio-container">{AppContext.r["payment-types"][orderType][3]}
                                    <input type="radio" value={PaymentTypesEnum.cashOnDelivery} onChange={this.handlePaymentMethodChange}
                                        checked={paymentType === PaymentTypesEnum.cashOnDelivery} />
                                    <span className="checkmark"></span>
                                </label>}

                            {orderType === OrderTypesEnum.collection &&
                                <label className="radio-container">{AppContext.r["payment-types"][orderType][3]}
                                    <input type="radio" value={PaymentTypesEnum.cashOnCollection} onChange={this.handlePaymentMethodChange}
                                        checked={paymentType === PaymentTypesEnum.cashOnCollection} />
                                    <span className="checkmark"></span>
                                </label>}
                        </div>
                    </div>
                )}

                {/* {(orderType === OrderTypesEnum.delivery && paymentType === PaymentTypesEnum.cashOnDelivery) &&
                    <>
                        <p className='payment-note'>Please have the exact money as the driver won’t have change.</p>
                        <p className='payment-note'>As soon as we have received the money, we will process the order. If you do not pay within three working days and have not stated cash on delivery, it will be cancelled.</p>
                    </> } */}

                {(total > 0 && orderType === OrderTypesEnum.delivery && this.state.contact) &&
                    <>
                        <h2>Delivery fee for your post code {this.state.contact.post_code}</h2>

                        {deliveryFee === 0 && total < AppContext.s["min-order-free-delivery"] &&
                            <p className="text-center">Great news. We deliver in your area for <b>FREE</b>.</p>}
                        {(deliveryFee === 0 && total >= AppContext.s["min-order-free-delivery"]) &&
                            <p className="text-center">Great news. <b>FREE</b> delivery for orders above £ {AppContext.s["min-order-free-delivery"]}.</p>}
                        {deliveryFee === 5 &&
                            <p className="text-center">We deliver in your area for £ {deliveryFee}.</p>}
                        {deliveryFee === 10 &&
                            <p className="text-center">A courier will be arranged at a flat rate of £ {deliveryFee}.</p>}
                    </>
                }

                {this.state.showCheckout && paymentType && ((orderType === OrderTypesEnum.delivery && this.state.contactId) || orderType !== OrderTypesEnum.delivery) &&
                    ((this.props.enablePayment && paymentType === PaymentTypesEnum.card) ?
                        <>
                            <Button className="gotopayment-button"
                                onClick={() => this.setState({
                                    step: 1, total: cartCheckoutTotal(this.props.cart), deliveryFee: this.calcDeliveryFee()
                                })}>Go to payment <KeyboardArrowRight /></Button>
                        </> :
                        <Button type="submit" className="rounded-button checkout-button"
                            disabled={total > 0 && !this.state.agreed}
                            onClick={this.checkout}>
                            {total > 0 ?
                                <strong>{AppContext.r["checkout"]} {AppContext.s['currency']} {total.toFixed(2)}</strong>
                                : <strong>{AppContext.r["purchase-method-quote-request"]}</strong>}
                        </Button>)}
            </div>);

        return (
            <div className="shopping-cart">
                {step === 0 && cart}

                {(!user && this.props.cart.length > 0) &&
                    <p className="login-or-register">
                        <Button variant="link" onClick={this.props.authActions.onLogin}>{AppContext.r['login']}</Button> or
                        <Button variant="link" onClick={this.props.authActions.onRegister}>{AppContext.r['signup']}</Button> {AppContext.r["access-to-checkout"]}
                    </p>}

                {(user && this.props.cart.length > 0 && step === 0) &&
                    <>
                        {(total >= AppContext.s['min-order'] || disableMinOrder) ?
                            optionsView : <p className="text-center"><br /><b>Min. order to checkout £ {AppContext.s['min-order']}</b></p>}

                        {paymentType === PaymentTypesEnum.card && stepperComponent}
                    </>}

                {(user && this.props.cart.length > 0 && step > 0) &&
                    <>
                        {stepperComponent}

                        <Button className="backtocart-button"
                            onClick={() => this.setState({ step: 0 })}><KeyboardArrowLeft /> Back to cart</Button>
                        {console.log(`Amount: ${total}, Delivery Fee: ${deliveryFee}`)}
                        {promise &&
                            <div className="stripe">
                                <Elements stripe={promise}>
                                    <StripeCheckout testMode={store.stripe_test}
                                        request={request}
                                        user={user}
                                        amount={/*AppContext.s['currency'] + " " + */(total + deliveryFee).toFixed(2)}
                                        onSucceeded={this.checkout} />
                                </Elements>
                            </div>}
                    </>
                }
            </div>
        )
    }
}

export function cartCheckoutTotal(items, deliveryFee = 0) {
    // Calculate Total
    let total = 0;

    for (let item of items) {
        let totalExtras = 0;

        for (let extra of item.extras) {
            totalExtras += extra.price;
        }

        if (!item.product) {
            total = 0;
            continue;
        }

        const basePrice = isNaN(item.product.price) ||
            (item.product.shop_item_category.is_retail !== 1 && AppContext.s['project-id'] !== ProjectIdsEnum.Enoteca) ? 0 : parseFloat(item.product.price);
        const price = basePrice + totalExtras - ((basePrice + totalExtras) * item.product.percent_discount / 100);

        total += price * item.quantity;
    }

    return total + parseFloat(deliveryFee);
}

export function itemTotalPrice(item) {
    const { product } = item;
    let totalExtras = 0;

    if (item.extras)
        for (let extra of item.extras) {
            totalExtras += extra.price;
        }

    return (product.price && !isNaN(parseFloat(product.price))) ? (parseFloat(product.price) + parseFloat(totalExtras)) : 0;
}

export function priceTag(item) {
    const { product } = item;

    if (!product)
        return "";

    const price = itemTotalPrice(item);

    let priceLabel = "";
    if (product.percent_discount > 0)
        priceLabel = (
            <p className="price text-center">
                <span className="line-through">{AppContext.s["currency"] + " " + parseFloat(price).toFixed(2)}</span> &nbsp;
                <span>{AppContext.s["currency"] + " " + (price - price * product.percent_discount / 100).toFixed(2)}</span> &nbsp;
                <span>({product.percent_discount}%)</span>
            </p>);
    else
        priceLabel = <p className="price text-center">{AppContext.s["currency"] + " " + (price ? parseFloat(price).toFixed(2) : price)}</p>;

    return priceLabel;
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        cart: state.cart.cart,
        // cartUpdated: () => { return true }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        addToCart: (product) => {
            dispatch(cart.actions.addToCart(product));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withOverlay(Cart));