import React, { Component, Children, isValidElement, cloneElement } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import AppContext from './AppContext';
import ProjectIdsEnum from "../settings/ProjectIdsEnum";
import Login from "app/modules/Auth/pages/Login";
import Auth from '../api/Auth';
import { withModal } from '../framework/Modal';
import Contacts from '../framework/Contacts';
import BrandsCarousel from 'components/BrandsCarousel';
import Footer from "./Footer.js";
import CookiesNotification from "framework/CookiesNotification";

import Logo from 'components/Logo';

import Header from "components/Header/Header.js";
import HeaderLinks from './HeaderLinks.js';

// const qs = require('querystring');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Layout extends React.Component {
    state = {
        isApp: false,
        notification: null
    }

    updateLayout = (scrollUp = true) => {
        const slides = document.getElementsByClassName("fullscreen");

        for (var i = 0; i < slides.length; i++) {
            const e = slides.item(i);

            e.style.height = window.innerHeight + "px";
        }

        const heightAsWidthItems = document.getElementsByClassName("height-as-width");

        for (i = 0; i < heightAsWidthItems.length; i++) {
            const e = heightAsWidthItems.item(i);

            e.style.height = e.offsetWidth + "px";
        }
        
        if(scrollUp) {
            const body = document.querySelector('#root');

            body.scrollIntoView({
                behavior: 'smooth'
            }, 500);
        }
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({
            notification: null
        })
    };

    async componentDidMount() {
        this.updateLayout();
        
        if(this.props.token) {
            const response = await Auth.verify(this.props.token);
            
            if(response && response.status === 200) {
                this.setState({
                    notification: AppContext.r["account-verified"]
                });
                
                AppContext.history.push("/");
            }
        }

        this.isApp = false;
        // This should be moved "higher"
        // const searchQuery = qs.parse(AppContext.history.location.search.replace("?", ""));
        // this.isApp = searchQuery.app && searchQuery.app.length > 0;
        // if(isApp && searchQuery.a && searchQuery.a.length > 0) {
        //     user = await Auth.appLogin(JSON.parse(searchQuery.a));
        // } else {
        //     // Try to autologin
        //     user = await Auth.autologin()
        // }
        // const { user } = this.props.auth;
    }

    onLogin = (registering = false) => {
        this.props.modal.setView(<><i className="fas fa-user"></i> {AppContext.r["login"]}</>, 
            <Login modal={this.props.modal} isApp={this.state.isApp} registering={registering}
                onLoggedIn={this.onLoggedIn} onResetPasswordRequested={this.props.modal.hide} />, true);
    }

    onLoggedIn = async (response) => {
        this.setState({
                user: Auth.user
            }
        );

        if(this.onObserverAccessed)
            this.onObserverAccessed(Auth.user);
    }

    // Invoked by the app
    onLoggedWithFacebook = async (data) => {
        const response = await AppContext.Auth.login(data.email, null, "social", "facebook", data.token, data.first_name, data.last_name);

        if(response) {
            if (response.status === 200) {
                this.props.modal.hide();

                if(this.onObserverAccessed)
                    this.onObserverAccessed(AppContext.Auth.user);
            } else {
                // Authorization Failed
                this.setState({
                    modalBusy: false,
                    modalMessage: <div>
                        <i className="far fa-frown icon"></i>
                        <p className="message">{AppContext.r["l-wrong-credentials"]}</p>
                        <Button onClick={this.setModalFree}>{AppContext.r['close']}</Button>
                    </div>
                });
            }
        }
    }

    onManageContacts = () => {
        this.props.modal.setView(AppContext.r["contacts"], <Contacts />, true);
    }

    onLoggedOut = () => {
        Auth.logout();

        this.setState({
            user: null
        })

        if(this.onObserverLoggedOut)
            this.onObserverLoggedOut();
    }

    onToggleUserSettings = (toggleOpen) => {
        this.setState({
            showUserSettings: toggleOpen ? toggleOpen : !this.state.showUserSettings
        });
    }
    
    render() {
        const onLogin = this.onLogin;

        const authActions = {
            onLogin: this.onLogin,
            onRegister: () => { this.onLogin(true); }
        };

        const childrenWithProps = Children.map(this.props.children, child => {
            // Checking isValidElement is the safe way and avoids a TS error too.
            if (isValidElement(child)) {
                return cloneElement(child, { authActions: authActions, updateLayout: this.updateLayout })
            }

            return child;
        });

        return (
            <div className={(AppContext.s["project-id"] === ProjectIdsEnum.Enoteca) ? "enoteca-theme" : "rafalauk-theme"}>
                <CookiesNotification />
                
                <Header brand={<Logo />} fixed color="transparent" 
                    authActions={authActions}
                    onLogin={this.onLogin}
                    changeColorOnScroll={{
                        height: 180,
                        color: "primary"
                    }}
                    links={<HeaderLinks />} />

                <div className={"layout " + this.props.className}>
                    {childrenWithProps}
                </div>
                
                <BrandsCarousel />
                
                <MessengerCustomerChat htmlRef={window.location.pathname}
                    pageId={AppContext.s["facebook-page-id"]} appId={AppContext.s["facebook-app-id"]} />

                <Footer />
            </div>
        );
    }
}

// const mapStateToProps = state => ({
//     auth: state.auth
// });

// export default connect(mapStateToProps, null)(withModal(Layout));

const mapDispatchToProps = (dispatch) => {
    return {
        resetCart: () => {
            dispatch(cart.actions.resetCart());
        }
    }
};

export default withModal(connect(null, mapDispatchToProps)(Layout));