import React from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import AppContext from '../../app/AppContext';
import { cartCheckoutTotal } from './Cart'

export class ToggleCartButton extends React.Component {
    onClick = () => {
        if(this.props.onClick)
            this.props.onClick();
    }

    render() {
        this.props.cartUpdated();
 
        const total = cartCheckoutTotal(this.props.cart);

        return (
            <Button variant="link" className="toggle-cart-button" onClick={this.onClick}>    
                {
                    this.props.cart.length > 0 ? (
                        <span className="label label-info">{ this.props.cart.length } ({AppContext.s['currency']} {total.toFixed(2)})</span>
                    ) : null
                }
                <i className="fas fa-shopping-cart"></i>
            </Button>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // products: state.product.products,
        cart: state.cart.cart,
        cartUpdated: () => { return true }
    }
};

export default connect(mapStateToProps, null)(ToggleCartButton);