import Axios from 'axios';

import AppContext from '../app/AppContext'
import CoreApi from './Core';

export default class Stripe {
    static async CheckoutSession(shop, product) { //, customerId = null
        const data = {
            priceId: product.priceId,
            shopId: shop.id,
            name: product.title
        };

        console.log(data);
        // if(customerId)
        //     data["customerId"] = customerId;

        return await Axios.post(AppContext.s["api-url"] + "/stripe/createCheckoutSession", data)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async CustomerPortal(shopId) {
        const data = {
            shopId: shopId
        };

        return await Axios.post(AppContext.s["api-url"] + "/stripe/customerPortal", data)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async Services(shopId) {
        return await Axios.get(AppContext.s["api-url"] + "/services?shopId=" + shopId)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async Subscriptions() {
        return await Axios.get(AppContext.s["api-url"] + "/subscriptions")
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async PaymentMethods(shopId, isTestMode = 0) {
        console.log(AppContext.s["api-url"] + "/stripe/paymentMethods?test=" + isTestMode + "&shop_id=" + shopId)
        return await Axios.get(AppContext.s["api-url"] + "/stripe/paymentMethods?test=" + isTestMode + "&shop_id=" + shopId)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async SetupIntent(isTestMode = 0) {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/setupIntent", { test: isTestMode } )
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }

    static async DetachPaymentMethod(shopId, paymentMethod, isTestMode = 0) {
        const config = {
            params: { 
                payment_method: paymentMethod, 
                test: isTestMode,
                shop_id: shopId
            }
        };

        return await Axios.delete(AppContext.s["api-url"] + "/stripe/detachPaymentMethod", config)
            .catch((error) => {
                console.log('error ' + error);
                CoreApi.errorLog(error)
            });
    }
}