import React from "react";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "./Routes";
import { LayoutSplashScreen } from "../_metronic/layout";

import {loadResources} from "../resources/resources";

import AppContext from './AppContext';

var hist = AppContext.history = createBrowserHistory();

loadResources();

export default function App({ store, persistor, basename }) {
    const domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    AppContext.Subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    AppContext.IsRetail = AppContext.Subdomain !== "trade" ? 1 : 0;
    console.log(domain, AppContext.Subdomain);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename} history={hist}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              {/* Provide `react-intl` context synchronized with Redux state.  */}
                {/* Render routes with provided `Layout`. */}
                <Routes />
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}