import React from 'react';

import AppContext from '../../app/AppContext';

export default function loadClientResources (resources) {
    resources["privacy-policy-doc"] = (
        <div>
            <h2>{AppContext.s["provider"].name} Privacy Statement</h2>
            
            <hr />
    
            <h2>Introduction</h2>
            
            <p>Uderly Ltd (No: 12293155. Registered in England and Wales.) | VAT No. 338832575 (hereafter, "<b>Uderly</b>"), operator of the website {AppContext.s["project-website"]} (hereafter, the "<b>Website</b>"), as <b>owner of the processing  of personal data</b>  of users of this Website (hereafter, the "<b>Users</b>") below provides the privacy policy pursuant to art. 13 of EU Regulation 2016/679 of 27 April 2016 {/*(hereinafter, " <b>Regulation</b> " or " <b>Applicable Regulations</b> ")*/}.</p>

            <p>Your privacy is important to {AppContext.s["provider"].name}. This privacy statement provides information about the personal information that {AppContext.s["provider"].name} collects, and the ways in which {AppContext.s["provider"].name} uses that personal information.</p>
            
            <h2>Personal information collection</h2>
            
            <p>{AppContext.s["provider"].name} may collect and use the following kinds of personal information: </p>
            <ul>
                <li>information about your use of this Website;</li>
                <li>information that you provide using for the purpose of registering with the Website;</li>
                <li>information about transactions carried out over this Website;</li>
                <li>information that you provide for the purpose of subscribing to the Website services;</li>
                <li>any other information that you send to {AppContext.s["provider"].name};</li>
            </ul>
            
            <h2>Using personal information</h2>
            
            <p>{AppContext.s["provider"].name} may use your personal information to: </p>
            
            <ul>
                <li>administer this Website;</li>
                <li>personalize the Website for you;</li>
                <li>enable your access to and use of the Website services;</li>	
                <li>publish information about you on the Website;</li>
                <li>send to you products that you purchase;</li>
                <li>supply to you services that you purchase and send to you statements and invoices;</li>
                <li>collect payments from you;</li>
                <li>send you marketing communications.</li>
            </ul>
            
            <p>Where {AppContext.s["provider"].name} discloses your personal information to its agents or sub-contractors for these purposes, the agent or sub-contractor in question will be obligated to use that personal information in accordance with the terms of this privacy statement.</p>
            
            <p>In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, {AppContext.s["provider"].name} may disclose your personal information to the extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings, and in order to establish, exercise or defend its legal rights.</p>
            
            <h2>Securing your data</h2>
            
            <p>{AppContext.s["provider"].name} will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>

            <p>{AppContext.s["provider"].name} will store all the personal information you provide [on its secure servers].</p>
            
            <p>Information relating to electronic transactions entered into via this Website will be  protected by encryption technology.</p>
            
            <h2>Cross-border data transfers</h2>
            
            <p>Information that {AppContext.s["provider"].name} collects may be stored and processed in and transferred between any of the countries in which {AppContext.s["provider"].name} operates to enable the use of the information in accordance with this privacy policy.</p>
            
            <p>In addition, personal information that you submit for publication on the Website will be published on the internet and may be available around the world.</p>
            
            <p>You agree to such cross-border transfers of personal information.</p>
            
            <h2>Updating this statement</h2>
            
            <p>{AppContext.s["provider"].name} may update this privacy policy by posting a new version on this Website.</p> 

            <p>You should check this page occasionally to ensure you are familiar with any changes.</p>

            <h2>Other websites</h2>

            <p>This Website may contain links to other websites.</p>

            <p>{AppContext.s["provider"].name} is not responsible for the privacy policies or practices of any third party.</p>

            <h2>Contact {AppContext.s["provider"].name}</h2>

            <p>If you have any questions about this privacy policy or {AppContext.s["provider"].name}’s treatment of your personal information, please <a href={AppContext.s["provider"].contactFormUrl} target="_blank">contact us</a>.</p>
        </div>);
        
    resources["terms-doc"] = (
        <div>
            <h2>{AppContext.s["project-name"]}'s Terms and Conditions</h2>
            
            <hr class="small" />
            
            <h2>Introduction</h2>

            <p>Uderly Ltd (No: 12293155. Registered in England and Wales.) | VAT No. 338832575 (hereafter, "<b>Uderly</b>"), operator of the website {AppContext.s["project-website"]}, as <b>owner of the processing of personal data</b> of users of this Website below provides the related terms and conditions.</p>
            
            <p>These terms and conditions govern your use of "{AppContext.s["project-name"]}", meant as its website ({AppContext.s["project-website"]}), all its sub-domains and software related to and downloaded from. By using {AppContext.s["project-name"]}, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use {AppContext.s["project-name"]}.</p>

            <p>You must be at least 16 years of age to use {AppContext.s["project-name"]}. By using {AppContext.s["project-name"]} and by agreeing to these terms and conditions you warrant and represent that you are at least 16 years of age.</p>

            <p>{AppContext.s["project-name"]}'s website uses cookies. By using this website and agreeing to these terms and conditions, you consent to {AppContext.s["provider"].name}'s use of cookies in accordance with the terms of {AppContext.s["project-name"]}'s privacy policy and cookies policy.</p>

            <h2>License to use {AppContext.s["project-name"]}</h2>

            <p>Unless otherwise stated, {AppContext.s["provider"].name} and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>

            <p>You may view, download and print pages or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>

            <p>You must not:</p>

            <li>republish material from this website (including republication on another website);</li>
            <li>sell, rent or sub-license material from the website;</li>
            <li>reproduce, duplicate, copy or otherwise exploit material on this website for your own commercial purposes without {AppContext.s["provider"].name} consent;</li>
            <li>edit or otherwise modify any material on the website;</li>
            <li>redistribute material from this website (except for content specifically and expressly made available for redistribution).</li>

            <p>Where content is specifically made available for redistribution, it may only be redistributed within your organisation.</p>

            <h2>Acceptable use</h2>

            <p>You must not use {AppContext.s["provider-name"]} in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>

            <p>You must not use {AppContext.s["provider-name"]} to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>

            <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without {AppContext.s["provider"].name}’s express written consent.</p>

            <p>You must not use this website to transmit or send unsolicited commercial communications.</p>
            
            <h2>Restricted access</h2>

            <p>Access to certain areas of this website is restricted. {AppContext.s["provider"].name} reserves the right to restrict access to [other] areas of this website, or indeed this entire website, at {AppContext.s["provider"].name}’s discretion.</p>

            <p>If {AppContext.s["provider"].name} provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential.</p>

            <p>{AppContext.s["provider"].name} may disable your user ID and password in {AppContext.s["provider"].name}’s sole discretion without notice or explanation.</p>

            <h2>User content</h2>

            <p>In these terms and conditions, “your user content” means material (including without limitation software, text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</p>

            <p>You grant to {AppContext.s["provider"].name} a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your user content in any existing or future media. You also grant to {AppContext.s["provider"].name} the right to sub-license these rights, and the right to bring an action for infringement of these rights.</p>

            <p>Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or {AppContext.s["provider"].name} or a third party (in each case under any applicable law).</p>

            <p>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>
            
            <p>{AppContext.s["provider"].name} is not responsible in any way of illegal or not ethical content submitted by its users.</p>

            <p>{AppContext.s["provider"].name} reserves the right to edit or remove any material submitted to this website, or stored on {AppContext.s["provider"].name} servers, or hosted or published upon this website.</p>

            <p>Notwithstanding {AppContext.s["provider"].name}’s rights under these terms and conditions in relation to user content, {AppContext.s["provider"].name} does not undertake to monitor the submission of such content to, or the publication of such content on, this website.</p>

            <h2>Rights you grant us</h2>
            
            <p>You grant {AppContext.s["provider"].name} the right to use the hardware resources and bandwidth on your device in order to facilitate the operation of the service, to provide advertising and other information to you and to allow our business partners to do the same. In any part of this website and the software provided on this website, the content you view may be influenced by commercial considerations, including agreements with third parties. Some content licensed or provided to {AppContext.s["provider"].name} may contain advertising as part of the content.</p>
            
            <h2>No warranties</h2>

            <p>{AppContext.s["provider"].name} is provided “as is” without any representations or warranties, express or implied. {AppContext.s["provider"].name} makes no representations or warranties in relation to this website or the information and materials provided on this website.</p>

            <p>Without prejudice to the generality of the foregoing paragraph, {AppContext.s["provider"].name} cannot warrant that:</p>

            <li>this website will be constantly available, or available at all; or</li>
            <li>the information on this website is always complete, true, accurate or non-misleading.</li>

            <p>Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any [legal, financial or medical] matter you should consult an appropriate professional.</p>
            
            <p>A no-profit licence could be requested from a no-profit entity representative but it is not guaranteed that this will be provided and the choice to provide it remain entirely at {AppContext.s["provider"].name}'s discretion.</p>
            
            <h2>Limitations of liability</h2>

            <p>We will not be liable to you (whether under the law of contract, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with {AppContext.s["provider"].name}:</p>

            <li>[to the extent that the website is provided free-of-charge, for any direct loss;]</li>
            <li>for any indirect, special or consequential loss; or</li>
            <li>for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</li>

            <p>These limitations of liability apply even if {AppContext.s["provider"].name} has been expressly advised of the potential loss.</p>

            <h2>Exceptions</h2>

            <p>Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit.</p>{/* and nothing in this website disclaimer will exclude or limit {AppContext.s["provider"].name}’s liability in respect of any:

            <li>death or personal injury caused by {AppContext.s["provider"].name}’s negligence;</li>
            <li>fraud or fraudulent misrepresentation on the part of {AppContext.s["provider"].name}; or</li>
            <li>matter which it would be illegal or unlawful for {AppContext.s["provider"].name} to exclude or limit, or to attempt or purport to exclude or limit, its liability. </li>*/}

            <h2>Reasonableness</h2>

            <p>By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.</p>  

            <p>If you do not think they are reasonable, you must not use this website.</p>

            <h2>Other parties</h2>

            <p>You accept that, as a limited liability entity,  {AppContext.s["provider"].name} has an interest in limiting the personal liability of its officers and employees.  You agree that you will not bring any claim personally against {AppContext.s["provider"].name}’s officers or employees in respect of any losses you suffer in connection with the website or its content.</p>

            <p>Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect {AppContext.s["provider"].name}’s officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as {AppContext.s["provider"].name}</p>

            <h2>Unenforceable provisions</h2>

            <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>

            <h2>Indemnity</h2>

            <p>You hereby indemnify {AppContext.s["provider"].name} and undertake to keep {AppContext.s["provider"].name} indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by {AppContext.s["provider"].name} to a third party in settlement of a claim or dispute on the advice of {AppContext.s["provider"].name}’s legal advisers) incurred or suffered by {AppContext.s["provider"].name} arising out of any breach by you of any provision of these terms and conditions[, or arising out of any claim that you have breached any provision of these terms and conditions].</p>

            <h2>Breaches of these terms and conditions</h2>

            <p>Without prejudice to {AppContext.s["provider"].name}’s other rights under these terms and conditions, if you breach these terms and conditions in any way, {AppContext.s["provider"].name} may take such action as {AppContext.s["provider"].name} deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>

            <h2>Variation</h2>

            <p>{AppContext.s["provider"].name} may revise these terms and conditions from time-to-time.  Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website.  Please check this page regularly to ensure you are familiar with the current version.</p>

            <h2>Assignment</h2>

            <p>{AppContext.s["provider"].name} may transfer, sub-contract or otherwise deal with {AppContext.s["provider"].name}’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>

            <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>

            <h2>Severability</h2>

            <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>

            <h2>Entire agreement</h2>

            <p>These terms and conditions constitute the entire agreement between you and {AppContext.s["provider"].name} in relation to your use of this website and all the related software, and supersede all previous agreements in respect of your use of {AppContext.s["provider"].name}.</p>

            <h2>Law and jurisdiction</h2>

            <p>These terms and conditions will be governed by and construed in accordance with the laws of England and Wales and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
            
            <p>You can contact {AppContext.s["provider"].name} by our <a href={AppContext.s["provider"].contactFormUrl} target="_blank">contact form</a>.</p>
        </div>
    );
}