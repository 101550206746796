import React from 'react';
import Slider from "react-slick";

import AppContext from 'app/AppContext';
import RafalaApi from 'api/Rafala';

export default class BrandsCarousel extends React.Component {
    state = {
        brands: null
    };

    async componentDidMount() {
        const response = await RafalaApi.Brands(AppContext.s["project-id"]);

        if (response && response.data)
            this.setState({
                brands: response.data.data
            });
    }

    render() {
        const itemComp = (item, index) => {
            const url = `https://api.rafalauk.com/api/v1/shopitems/brands/${item.id}/image/thumb`;
            const placeholder = AppContext.s['placeholder'];

            return (
                <>
                    <div className="brand-card" 
                        style={!item.image ? {backgroundImage: `url(${placeholder})`} : {}}>
                        <div className="image-wrapper">
                            {item.image && <img src={url} />}
                        </div>
                        <h3>{item.name}</h3>
                    </div>
                </>);
        }

        if(!this.state.brands)
            return "";
        //     return AppContext.r["preloader"];

        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 1500,
            speed: 500,
            slidesToShow: 10,
            slidesToScroll: 1,
            arrows: false,
            pauseOnHover: false,
            responsive: [
                {
                  breakpoint: 1500,
                  settings: {
                    slidesToShow: 7,
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 5,
                  }
                },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                }
              }
              // You can unslick at a given breakpoint now by adding:
              // settings: "unslick"
              // instead of a settings object
            ]
        };

        // Only show items with specified image
        const brands = this.state.brands.filter((item) => item.image);

        return (
            <div className="brands-carousel">
                <h2 className="our-brands-title">Our Brands</h2>

                <Slider {...settings}>
                    {brands.map((item, index) => itemComp(item, index))}
                </Slider>
            </div>);
    }
}