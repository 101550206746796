import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';

import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import ScrollIntoView from 'react-scroll-into-view';

import AppContext from 'app/AppContext';
import ProjectIdsEnum from "../../settings/ProjectIdsEnum";
import Banner from "../Banner";
import ProductsGallery from "components/ProductsGallery";

const qs = require('querystring');

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}
  
export default class Home extends React.Component {
    state = {
        search: "",
        showPopup: false,
        showRedirectAlert: false,
        redirectPopupAlreadyClosed: false
    };

    constructor(props) {
        super(props);

        const searchQuery = qs.parse(AppContext.history.location.search.replace("?", ""));

        if(searchQuery.from === "rafala")
            this.state = {
                ...this.state,
                showRedirectAlert: true
            };
    }
    
    componentDidMount() {
        if(this.props.updateLayout)
            this.props.updateLayout();

        window.addEventListener("scroll", this.onScroll);

        setTimeout(() => {
            this.setState({
                showPopup: true
            })
        }, 15000);
    }

    onScroll() {
    }

    handleChange = (event) => {
        this.setState({
            search: event.target.value
        });
    }

    resetSearch = (event) => {
        this.setState({
            search: ""
        });
    }

    handleClosePopup = () => {
        this.setState({
            showPopup: false
        });
    }

    onSelectCategory = () => {
        // alert("onSelectCategory");
    }

    render() {
        const {search, showPopup, redirectPopupAlreadyClosed, showRedirectAlert} = this.state;

        // const searchBox = (
        //     <ScrollIntoView selector={"#searchBox"}>
        //         <FormControl variant="filled" className="search-box">
        //             <div id="searchBox" className="anchor"></div>

        //             <InputLabel htmlFor="standard-adornment-password">Search Products</InputLabel>
        //             {/* <small>Type at least 3 letters</small> */}
        //             <FilledInput
        //                 id="standard-adornment-password"
        //                 value={this.state.search}
        //                 onChange={this.handleChange}
        //                 endAdornment={
        //                     <InputAdornment position="end">
        //                         <IconButton aria-label="toggle reset">
        //                             {search.length > 0 ? 
        //                                 <ClearIcon 
        //                                     onClick={this.resetSearch}
        //                                     onMouseDown={this.resetSearch}/> : <SearchIcon />}
        //                         </IconButton>
        //                     </InputAdornment>
        //                 } />
        //         </FormControl>
        //     </ScrollIntoView>
        // );
                
        const scrollTo = (selector, navBarHeight = 70) => {
            const el = document.querySelector(selector);

            if(el) {
                const r = el.getBoundingClientRect();
                window.scrollTo(r.left, r.top + window.scrollY - navBarHeight);
            }
        };

        const searchBox = (
            <FormControl variant="filled" className="search-box"
                onClick={() => scrollTo("#gallery", 100)}>
                <InputLabel htmlFor="standard-adornment-password">Search Products</InputLabel>
                
                <FilledInput
                    id="standard-adornment-password"
                    value={this.state.search}
                    onChange={this.handleChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle reset">
                                {search.length > 0 ? 
                                    <ClearIcon 
                                        onClick={this.resetSearch}
                                        onMouseDown={this.resetSearch}/> : <SearchIcon />}
                            </IconButton>
                        </InputAdornment>
                    } />
            </FormControl>
        );

        const popupCard = (
            <div className="popup-card">
                <div className="actions">
                    <IconButton aria-label="close" color="inherit"
                        onClick={this.handleClosePopup}>
                        <CloseIcon />
                    </IconButton>
                </div>

                {AppContext.r["popup-desc1"]}

                <Button className="shop-link" color="secondary" size="small" target="_blank"
                    href={"https://www.enotecawineshop.co.uk?from=rafala"}>
                    Shop Wines
                </Button>
            </div>
        );

        return (
            <>
                <Banner />

                {searchBox}

                <div id="ourProducts" className="anchor"></div>
                
                <Container fluid>
                    <Row className="our-products justify-content-center">
                        <Col md={8}>
                            <h2>Our Products</h2>
                            <p className="text-center">{AppContext.r['our-products-desc']}</p>
                        </Col>
                    </Row>

                    <ProductsGallery 
                        shopId={AppContext.s['project-id']}
                        category={this.props.category} 
                        onSelectCategory={this.onSelectCategory}
                        search={this.state.search} />
                    
                    {/* {AppContext.s["project-id"] === ProjectIdsEnum.RafalaUk && 
                        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            open={showPopup}
                            onClose={this.handleClosePopup}
                            TransitionComponent={SlideTransition}
                            message={popupCard} /> } */}

                    {AppContext.s["project-id"] === ProjectIdsEnum.Enoteca && !redirectPopupAlreadyClosed &&
                        <Snackbar open={showRedirectAlert} autoHideDuration={6000} onClose={() => {}}>
                            <Alert severity="info" onClose={() => { this.setState({ showRedirectAlert: false })}}>
                                <AlertTitle>Info</AlertTitle>
                                You have been redirected to our sister site, Enoteca. Please browse our products and get in
                                touch if you would like any advice. If you order food and wine, they will have two different
                                order numbers but will be delivered together unless you request otherwise. 
                                If you are already registered with us, you don't need to sign up again and can login with your Rafala UK's account.
                            </Alert>
                        </Snackbar> }
                </Container>
            </>
        );
    }
}
