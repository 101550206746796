import React from 'react';

import AppContext from 'app/AppContext';

import logo from "assets/images/logo.png";
import logo48 from "assets/images/logo-48.png";
import logo64 from "assets/images/logo-64.png";

export default class Logo extends React.Component {
    // componentDidMount() {
    //     alert(AppContext.s['mini-logo-dark']);
    // }
    render() {
        if(this.props.size === "big")
            return <img className="logo-big" src={AppContext.s['logo-dark']} />;

        return <img className="logo" src={AppContext.s['mini-logo-dark']} />;
    }
}