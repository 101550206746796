// CookiesNotification v1.0

import React from 'react';
import Cookie from 'react-cookies';

import AppContext from 'app/AppContext';

export default class CookiesNotification extends React.Component {
    state = {
        cookiesAccepted: false
    }

    componentDidMount() {
        const accepted = Cookie.load(AppContext.s["cookies-cookie-name"]);

        this.setState({
            cookiesAccepted: accepted ? accepted : false
        })
    }

    handleAcceptCookies = () => {
        Cookie.save(AppContext.s["cookies-cookie-name"], true, { path: '/', maxAge: 1000 * 24 * 3600 });

        this.setState({
            cookiesAccepted: true
        })
    }

    render() {
        return !this.state.cookiesAccepted ? (
            <div className="top-bar">
                <p>{AppContext.r["accept-cookies"]}</p>
                <button onClick={this.handleAcceptCookies}>{AppContext.r["accept"]}</button>
            </div>
        ) : "";
    }
}