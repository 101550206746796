import React from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

import AppContext from 'app/AppContext.js';

export default class Help extends React.Component {
    componentDidMount() {
        if(this.props.updateLayout)
            this.props.updateLayout();
    }
    
    render() {
        return (
            <Container fluid className="legal-container">
                <Row>
                    <h2>Payments, Deliveries and Returns</h2>
                    
                    <hr />

                    <h2>Registration</h2>

                    <p>
                        If you would like home delivery, you can <Button variant="link" onClick={this.props.authActions.onRegister}>register here</Button>. Then all you need to do is add the products to your basket, check out and wait for your delivery. Minimum order: £ {AppContext.s["min-order"]}. 
                    </p>

                    {AppContext.r["payments-help"]}

                    <p className="text-center">
                        <Link to="/contact">Contact Us</Link>
                    </p>
                </Row>
            </Container>
        );
    }
}
