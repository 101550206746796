// import React from 'react';
// import Settings from '../settings/settings';

export default function loadLocale(resources) {
    resources['accept-cookies'] = "Questo sito utilizza i cookies al fine di migliorare la tua esperienza di navigazione, mantenendo le tue preferenze quali ad esempio la lingua selezionata. Proseguendo alla navigazione presti il consenso all'uso di cookies.";
    resources['accept'] = "Accetta";
    resources['months'] = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];
    resources['days'] = "Giorni";
    resources['hours'] = "Ore";
    resources['minutes'] = "Minuti";
    resources['seconds'] = "Secondi";
    resources['phone'] = "Telefono";
    resources['email'] = "e-mail";
    resources['name'] = "Nome";
    resources['surname'] = "Cognome";
    resources['close'] = "Chiudi";
    resources['update'] = "Aggiorna";
    resources['follow-us-fb'] = "Seguici su Facebook";
    resources['follow-us-ig'] = "Seguici su Instagram";
    resources['contact-us'] = "Contattaci";
    resources['sponsored-by'] = "Sponsorizzato Da";
    resources['hosted-by'] = "Servizio di";
    resources['privacy-policy'] = "Privacy Policy";
    resources['terms'] = "Condizioni e Termini";
    resources['cancel'] = "Annulla";
    resources['delete'] = "Elimina";
    resources['save'] = "Salva";
    resources['profile-saved'] = "Il tuo profilo è stato aggiornato.";
    resources['address'] = "Indirizzo";
    resources['insert-new-address'] = "Inserisci Nuovo indirizzo";
    resources['address-alias'] = "Alias Indirizzo";
    resources["required-field"] = "Campo Obbligatorio.";
    resources["default-address"] = "Indirizzo di Casa";
    resources["post-code"] = "CAP";
    resources["city"] = "Città";
    resources["country"] = "Nazione";
    resources["confirm-delete-address"] = "Sei sicuro di volere eliminare questo indirizzo?";
    resources["confirm-delete-address-heading"] = "Elimina Indirizzo";
    resources["no-address-selected"] = "Non è stato specificato nessun indirizzo."
    resources["no-payment-type-selected"] = "Non è stato specificato nessun metodo di pagamento."
    resources["payment-method"] = "Metodo Di Pagamento"
    resources["purchase-method-order"] = "Comanda";
    resources["purchase-method-delivery"] = "Consegna";
    resources["purchase-method-collection"] = "Take Away";
    resources["purchase-method-quote-request"] = "Richiedi un preventivo";
    resources['mobile-phone'] = "Telefono";
    resources['email'] = "e-mail";
    resources['yes'] = "Sì";
    resources['no'] = "No";

    resources["access"] = "Accedi";

    resources["track-and-trace"] = "Track & Trace";
    resources["covid-checkin-desc"] = "Effettua il check in adesso per tenere traccia della tua visita.";
    resources["check-in-now"] = "Effettua il check-in";
    resources["already-checked-in"] = "Ho già effettuato il check-in oggi";

    resources["thank-you"] = "Grazie.";
}
