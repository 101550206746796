export default function loadClientSettings(settings) {
    settings['client'] = {
        // subTitle: 'Speciality Wine',
        website: 'www.enotecawineshop.co.uk',
        email: 'sales@enotecawineshop.co.uk',
        // phone: 'xxxxxxxxx',
        logoPath: 'assets/enoteca/images/brand.png',
        // coverPath: 'assets/enoteca/images/cover0.jpg',
    };
    settings["project-name"] = "Enoteca Wine Shop";
    settings["sub-title"] = "Wine, Beer and Spirits";
    // settings['logo-path'] = '/assets/enoteca/images/brand.png';
    settings["logo-dark"] = "/assets/enoteca/images/brand.png";
    settings["logo-light"] = "/assets/enoteca/images/brand.png";
    settings["mini-logo-dark"] = "/assets/enoteca/images/brand.png";
    settings["mini-logo-light"] = "/assets/enoteca/images/brand.png";
    settings['mini-logo-path'] = 'assets/enoteca/images/mini-logo-39.png';

    settings['facebook-page-url'] = 'https://www.facebook.com/Enoteca-Wine-Shop-Huntleys-1507109329419150';
    settings['instagram-page-url'] = '';

    settings["redux-auth-key"] = "enoteca-auth-key";
    settings["redux-cart-key"] = "enoteca-cart-key";

    settings['cover-path'] = 'assets/enoteca/images/cover0.jpg';
    settings['about-us'] = "Enoteca Wine Shop";
    settings['about-us-desc'] = <>Enoteca is a well-established Italian family business selling quality wine, beer and spirits out of our shop in Samlesbury, Lancashire.<br />The diversity of our range means that there is something for everyone and we are happy to advise and make suggestions for anyone who requests them.</>;
    settings['phone'] = "01772877075";
    settings['email'] = "sales@enotecawineshop.co.uk";
    settings['primary-color'] = "#f5bd4a";
    settings['secondary-color'] = "#ee442b";
}
